import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Axios from '../../utilities/axios'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

const Cacourses = () => {
  const [rows, setRows] = React.useState(null)
  React.useEffect(() => {
    let uid = localStorage.getItem('uid')
    Axios.get(`courses/get_all_courses_for_courseadmin/${uid}`)
      .then((res) => {
        let r = res?.data?.data[0]
        setRows(r)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  const columns = [
    { field: 'id', headerName: 'ID', width: 150, hide: true },
    { field: 'id', headerName: 'Course ID', width: 200 },
    { field: 'course_name', headerName: 'Course Name', width: 300 },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
    },
    {
      field: 'course_publish_unpublish_date',
      headerName: 'Publish Date',
      width: 180,
    },
    {
      field: 'created_at',
      headerName: 'Created Date',
      width: 180,
    },
    {
      field: 'course_duration',
      headerName: 'Duration',
      width: 190,
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      width: 180,
      type: 'date',
    },
    {
      field: 'student_count',
      headerName: 'Students',
      width: 160,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 400,
      renderCell: RenderDetailsButton,
      disableClickEventBubbling: true,
      filterable: false,
    },
  ]

  return (
    <>
      {rows ? (
        <div className="card height-auto">
          <div className="card-body">
           
            <div className="table-responsive" style={{ height: 650 }}>
              <DataGrid
                components={{
                  Toolbar: GridToolbar,
                }}
                rows={rows}
                columns={columns}
                pageSize={8}
                rowsPerPageOptions={[8]}
              />
            </div>
          </div>
        </div>
      ) : (
        <div id="preloader"></div>
      )}
    </>
  )
}

export default Cacourses

const RenderDetailsButton = (params) => {
  const history = useHistory()
  const parseName = (para) => {
    history.push(`/single course/${para?.row?.id}`)
  }
  const onEdit = (para) => {
    history.push(`/manage/${para?.row?.id}`)
  }
  const onPublish = (para) => {
    // history.push(`/manage/${para?.row?.id}`);
    let uid = localStorage.getItem('uid')
    let data = {
      course_id: para?.row?.id,
      user_id: uid,
      flag: para.row.status !== 'published' ? 'published' : 'unpublished',
    }
    Axios.post('courses/course_publish', data)
      .then((res) => {
        if (res.data.status === 200) {
          toast(res?.data?.message, {
            type: 'success',
            position: 'top-center',
            theme: 'dark',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          })
          para.api.setRows(res?.data?.data)
          // window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const onComingSoon = (para) => {
    let uid = localStorage.getItem('uid')
    let data = {
      course_id: para?.row?.id,
      user_id: uid,
      flag: 'coming_soon',
    }
    Axios.post('courses/course_publish', data)
      .then((res) => {
        if (res.data.status === 200) {
          toast(res?.data?.message, {
            type: 'success',
            position: 'top-center',
            theme: 'dark',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          })
          para.api.setRows(res?.data?.data)
          // window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const onRegStatus = (para) => {
    let uid = localStorage.getItem('uid')
    let data = {
      course_id: para?.row?.id,
      user_id: uid,
    }
    Axios.post('courses/course_reg_status', data)
      .then((res) => {
        if (res.data.status === 200) {
          toast(res?.data?.message, {
            type: 'success',
            position: 'top-center',
            theme: 'dark',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          })
          para.api.setRows(res?.data?.data)
          // window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <strong>
      <button
        variant="contained"
        color="primary"
        size="small"
        className="btn btn-primary"
        onClick={() => {
          parseName(params)
        }}
      >
        View
      </button>{' '}
      <button
        className="btn btn-success"
        onClick={() => {
          onEdit(params)
        }}
      >
        Manage
      </button>{' '}
      <button
        className={
          params?.row?.status === 'published'
            ? 'btn btn-dark'
            : 'btn btn-danger'
        }
        onClick={() => {
          onPublish(params)
        }}
      >
        {params?.row?.status !== 'published' ? 'Publish' : 'Unpublish'}
      </button>{' '}
      <button
        className={
          params?.row?.status === 'coming_soon'
            ? 'btn btn-warning'
            : 'btn btn-info'
        }
        onClick={() => {
          onComingSoon(params)
        }}
        disabled={params?.row?.status === 'coming_soon'}
      >
        Coming Soon
      </button>{' '}
      <button
        className={
          params?.row?.reg_status === 'opened'
            ? 'btn btn-success'
            : 'btn btn-danger'
        }
        onClick={() => {
          onRegStatus(params)
        }}
      >
        {params?.row?.reg_status !== 'opened'
          ? 'Registrations Closed'
          : 'Registrations Opened'}
      </button>
    </strong>
  )
}
