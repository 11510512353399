import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Axios from "../../utilities/axios";
import { useHistory } from "react-router-dom";
// import MenuItem from "@mui/material/MenuItem";
// import InputLabel from "@mui/material/InputLabel";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { uploadFile } from "react-s3";
// import { toast } from "react-toastify";
import moment from "moment-timezone";

// import moment
// import { moment } from "moment-timezone";



const Mycourses = () => {
  // courses / get_all_courses_for_teacher / 1633417307942 / 10989368 / 12139214;
  const [rows, setRows] = React.useState(null);
  const [courses, setCourses] = React.useState([]);
  const [clases, setClases] = React.useState([]);
  const [selectedClass, setSelectedClass] = React.useState("");
  const [selectedCourse, setSelectedCourse] = React.useState("");
  // const [pdfName, setPdfName] = React.useState("");
  // const [selectedFile, setSelectedFile] = React.useState(null);

  React.useEffect(() => {
    CallApi(selectedCourse, selectedClass);
    //eslint-disable-next-line
  }, []);
  // console.log(selectedClass, "selectedClass");
  // console.log(selectedCourse, "selectedCourse");
  React.useEffect(() => {
    CallApi();
    //eslint-disable-next-line
  }, [selectedCourse, selectedClass]);

  const CallApi = () => {
    const uid = localStorage.getItem("uid");
    Axios.get(
      `courses/get_all_courses_for_teacher/${uid}/${
        selectedCourse !== "" ? selectedCourse : 0
      }/${selectedClass !== "" ? selectedClass : 0}`
    )
      .then((res) => {
        let arr = res?.data?.data;
        let t = moment.tz.guess();
        console.log(t, "t");
        arr.map((item, i) => {
          item.slot_time = moment(item.slot_time)
            .tz(t)
            .format("dddd hh:mm A z");
          return item;
        });
        // let v = moment(.slot_time).tz(t).format("dddd hh:mm A z");
        // console.log(arr, "arr");
        // for (let i = 0; i < arr?.length; i++) {
        //   b[i] = arr[i];
        //   console.log(b[i], "b[i]");

        //   // console.log(v, "v", t);
        //   // b[i].slot_time =
        //   b[i].slot_time = moment(arr[i].slot_time)
        //     .tz(moment.tz.guess())
        //     .format("dddd hh:mm A z");
        // }
        //console.log(b, "arr Peter");
        setRows(arr.length > 0 ? arr : []);
        setCourses(res.data.courses);
        setClases(res.data.classes);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const columns = [
    { field: "id", headerName: "Course ID", width: 180, hide: true },
    { field: "course_name", headerName: "Course Name", width: 190 },
    {
      field: "course_duration",
      headerName: "Duration",
      width: 180,
    },
    { field: "class_name", headerName: "Class Name", width: 190 },
    { field: "slot_time", headerName: "Slot Time", width: 200 },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 180,
      type: "date",
    },
    {
      field: "assigned_by",
      headerName: "Assigned by",
      width: 210,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 220,
      type: "actions",
      renderCell: ViewButton,
    },
  ];

  return (
    <div>
      <div className="card height-auto">
        <div className="card-body">
          <div className="heading-layout1 row">
            <div className="item-title col-md-3">
              <h4>Courses</h4>
            </div>
            <div className="col-md-3"></div>
            {/* <div className="item-title col-md-3">
              <FormControl fullWidth>
                <InputLabel id="course-duration-select-helper-label">
                  Select Course
                </InputLabel>
                <Select
                  labelId="course-duration-select-helper-label"
                  id="course-duration-select-helper"
                  value={selectedCourse}
                  label="Course Duration"
                  onChange={(e) => setSelectedCourse(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {courses?.map((item) => (
                    <MenuItem value={`${item?.course_id}`}>
                      {item?.course_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}
            {/* <div className="item-title col-md-3">
              <FormControl fullWidth>
                <InputLabel id="course-duration-select-helper-label">
                  Select Class
                </InputLabel>
                <Select
                  labelId="course-duration-select-helper-label"
                  id="course-duration-select-helper"
                  value={selectedClass}
                  label="Course Duration"
                  onChange={(e) => setSelectedClass(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {clases?.map((item) => (
                    <MenuItem value={`${item?.class_id}`}>
                      {item?.class_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}
          </div>
          {rows && (
            <div className="table-responsive" style={{ height: 650 }}>
              <DataGrid
                components={{
                  Toolbar: GridToolbar,
                }}
                rows={rows}
                columns={columns}
                pageSize={8}
                rowsPerPageOptions={[8]}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Mycourses;

const ViewButton = (params) => {
  const history = useHistory();
  const func = (p) => {
    history.push(`/viewcourse/${p.row.course_id}`);
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-success"
        onClick={() => func(params)}
      >
        <i className="fas fa-eye" /> View Course
      </button>
    </>
  );
};
