import React from 'react'
import SpeakerModel from './SpeakerModel';
import image from '../../assests/img/books.JPG'
import dummy_image from "../../assests/img/figure/user3.jpg";
import { AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Glipmsecard from '../commonComponents/Glipmsecard';
import Accordion from "@mui/material/Accordion";
import { uploadFile } from "react-s3";
const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET,
  region: process.env.REACT_APP_REGION,
  dirName: "folder",
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

const AddSpeakers = ({
  speakersDetails,
  setSpeakersDetails,
  type,
  deletedSpeakers,
  setDeletedSpeakers

}) => {
  console.log(config,"configggg")
    const [modal, setModal] = React.useState(false);
    const toggle = () =>{ 
      setModal(!modal)
    if(modal){
      setSpeaker({
        speaker_name: "",
         speaker_image: "https://rushi2011.s3.amazonaws.com/folder/1638533356427_avator.png"
      })
    }
    };
    const [dummy, setdummy] = React.useState(true);
    const [speaker, setSpeaker] = React.useState({
        speaker_name: "",
         speaker_image: "https://rushi2011.s3.amazonaws.com/folder/1638533356427_avator.png"
        // glimpse_image: dummy_image,
      });
      const [editInx, setEditInx] = React.useState(null);
     
      // const [expanded, setExpanded] = React.useState(false);
      // const handleCh = (panel) => (event, isExpanded) => {
      //   setExpanded(isExpanded ? panel : false);
      // };

      const onEdit = (item, indx) => {
        toggle()
        setEditInx(indx);
        setSpeaker(item);
        
      };
      const updateSpeaker = () => {
        let data = speakersDetails;
        data.splice(editInx, 1, speaker);
        if (speakersDetails[editInx].id) {
          speakersDetails[editInx].state = "EDIT";
        }
        setSpeakersDetails  (data);
        setEditInx(null);
        resetSpeaker();
        setdummy(!dummy);
         toggle();
      };
      const resetSpeaker = () => {
        setSpeaker({
          speaker_name: "",
          speaker_image: "https://rushi2011.s3.amazonaws.com/folder/1638533356427_avator.png"
        });
      };

      const handleChangeSpeaker = (e) => {
        
        if (e?.target?.value?.includes("fakepath")) {
          callAfun(e);
        } else {
          
          setSpeaker((ev) => ({
            ...ev,
            [e.target.name]: e.target.value,
            // speaker_image:"https://rushi2011.s3.amazonaws.com/folder/1638533356427_avator.png",
            state: "ADD",
          }));
        }
      };
      const callAfun = async (e) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            handleUpload(e.target.files[0]);
            setdummy(!dummy);
          }
        };
        reader.readAsDataURL(e.target.files[0]);
      };
      const handleUpload = async (file) => {
        await uploadFile(file, config)
          .then((data) => {
            if (data?.result?.ok) {
              setSpeaker((ev) => ({
                ...ev,
                speaker_image: data?.location,
              }));
              //  handleSubmit(data.location);
            } else {
              alert("error");
            }
          })
          .catch((err) => console.log(err));
      };
      const addSpeaker = (e) => {
        let data = speakersDetails;
        
        data.push(speaker);
        setSpeakersDetails(data);
        setSpeaker({
          speaker_name: "",
          speaker_image: "https://rushi2011.s3.amazonaws.com/folder/1638533356427_avator.png"
        });
        toggle();
      };
      const deleteSpeaker = (index) => {
        let data = speakersDetails;
        let deleted = speakersDetails[index];
        if (deleted.id) {
          delete deleted.speaker_name;
          
          delete deleted.speaker_image;
          speakersDetails[index].state = "REMOVE";
          let l = [...deletedSpeakers];
          l.push(deleted);
          setDeletedSpeakers(l);
        }
        data.splice(index, 1);
        setSpeakersDetails(data);
        setdummy(!dummy);
      };

    

  return (
    <section className="mg-b-20">
      <div className="row gutters-8">
        <div className="col-12 mg-b-10 glipmse">
        { type!=='view'&&  <button
            type="button"
           onClick={toggle}
            className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark radius-30"
          >
            + Add Speakers
          </button>}
        </div>
      </div>
      <div className="row mg-b-20">
        {speakersDetails.length > 0
          ? speakersDetails.map((item, index) => (
              <div key={index} className="col-md-4 mg-b-20">
                <span className="g-actions">
                 { type!=='view'&& <i
                    className="fas fa-pencil-alt"
                   onClick={() => onEdit(item, index)}
                  ></i> }
                  { type!=='view'&&<i
                    className="fas fa-times pointer del-ac"
                  onClick={() => deleteSpeaker(index)}
                  ></i>}
                </span>
                <Accordion style={{padding: "15px"}}
                  // expanded={expanded === `panel${index + 1}`}
                  //  onChange={handleCh(`panel${index + 1}`)}
                >
                  <Glipmsecard
                      src={item.speaker_image}
                      name={item.speaker_name}
                     // number={item.speaker_description}
                    />
                  {/* <AccordionSummary>
                    <Glipmsecard
                      src={item.speaker_image}
                      name={item.speaker_name}
                     number={item.speaker_description}
                    />
                  </AccordionSummary> */}
                  {/* <AccordionDetails>
                    <Typography>{item?.speaker_description}</Typography>
                   </AccordionDetails> */}
                </Accordion> 
               </div>
            ))
          : " "} 
           
        <SpeakerModel
          modal={modal}
          toggle={toggle}
          speaker={speaker}
          setSpeaker={setSpeaker}
          updateSpeaker={updateSpeaker}
          editInx={editInx}
          handleChangeSpeaker={handleChangeSpeaker}
          resetSpeaker={resetSpeaker}
          addSpeaker={addSpeaker}
          
        />
      </div>
    </section>
  )
}

export default AddSpeakers