import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Axios from "../utilities/axios";

const Payments = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 150, hide: true },
    { field: "full_name", headerName: "Student Name", width: 190 },
    {
      field: "course_name",
      headerName: "Course Name",
      width: 190,
    },
    {
      field: "payment_id",
      headerName: "Payment Id",
      width: 180,
    },
    {
      field: "created_at",
      headerName: "Date of payment",
      width: 210,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
    },
    {
      field: "status",
      headerName: "Payment Status",
      width: 200,
      renderCell: RenderDetailsButton,
      disableClickEventBubbling: true,
    },
    {
      field: "phone_number",
      headerName: "Mobile Number",
      width: 200,
    },
    {
      field: "email_id",
      headerName: "E-mail Address",
      width: 200,
    },
  ];
  const [rows, setRows] = React.useState(null);
  const [pageSize, setPageSize] = React.useState(50);

  React.useEffect(() => {
    let uid = localStorage.getItem("uid");
    let url = `payments/get_payment_details_course_admin/${uid}`;
    Axios.get(url)
      .then((res) => {
        let row = res?.data?.data;
        setRows(row);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="card height-auto">
      {rows ? (
        <div className="card-body">
          <div
            className="table-responsive"
            style={{ height: 650, width: "100%" }}
          >
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
              // pageSize={10}
              // rowsPerPageOptions={[10]}
            />
          </div>
        </div>
      ) : (
        <div id="preloader"></div>
      )}
    </div>
  );
};

export default Payments;
const RenderDetailsButton = (params) => {
  return (
    <center>
      <button
        className={
          params?.row?.status === "success"
            ? "btn btn-success"
            : "btn btn-danger"
        }
      >
        {params?.row?.status}
      </button>
    </center>
  );
};
