import React, { useEffect } from "react";
import Axios from "../utilities/axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
// import { toast } from "react-toastify";
import swal from "sweetalert";

const Courseadmins = () => {
  const [rows, setRows] = React.useState(null);
  useEffect(() => {
    Axios.get("course_admin/get_all_courseadmins")
      .then((res) => {
        setRows(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    //eslint-disable-next-line
  }, []);
  const columns = [
    { field: "id", headerName: "Course Admin Id", width: 250 },
    { field: "fullname", headerName: "Name", width: 180 },
    {
      field: "total_courses",
      headerName: "Total Courses",
      type: "number",
      width: 180,
    },
    {
      field: "total_students",
      headerName: "Total Students",
      type: "number",
      width: 180,
    },
    {
      field: "total_sections",
      headerName: "Total Sections",
      type: "number",
      width: 185,
    },
    {
      field: "associated_from",
      headerName: "Associated From",
      type: "number",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 145,
      sortable: false,
      renderCell: RenderDetailsButton,
      disableClickEventBubbling: true,
    },
  ];
  // const history = useHistory();
  return (
    <>
      <div className="card height-auto">
        <div className="card-body">
          <div style={{ height: 600, width: "100%" }}>
            {rows ? (
              <DataGrid
                components={{
                  Toolbar: GridToolbar,
                }}
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[15]}
              />
            ) : (
              <div id="preloader"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Courseadmins;

const RenderDetailsButton = (params) => {
  let history = useHistory();
  const onDelete = (params) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let data = {
          user_id: params.row.id,
        };
        Axios.post("course_admin/delete_course_admin", data)
          .then((res) => {
            if (res.data.message === "not allowed to delete") {
              swal("Your Not Allowed to Delete!", {
                icon: "warning",
              });
            } else {
              swal("Course Admin has been deleted!", {
                icon: "success",
              });
              params.api.setRows(res?.data?.data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        swal("Your Operation is reverted!");
      }
    });
  };
  const onView = (params) => {
    history.push(`/courseadmin/${params.row.id}`);
  };
  return (
    <strong>
      <button
        className="btn btn-success"
        onClick={() => {
          onView(params);
        }}
      >
        <i className="fas fa-user"></i> View Profile
      </button>{" "}
      <button
        className="btn btn-danger"
        onClick={() => {
          onDelete(params);
        }}
      >
        <i className="fas fa-times"></i> Delete
      </button>
    </strong>
  );
};
