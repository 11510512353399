import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import moment from "moment";

const Schedule = ({
  pdfFileError,
  yearMonth,
  months,
  setFiless,
  allweaks,
  setAllweaks,
  s,
  addWeekTopics,
  handleTopicDelete,
  handleTopicReset,
  handleTopicChange,
  handleTopicPdfChange,
}) => {
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [dummy, setDummy] = useState(null);
  const [indexxx, setIndexxx] = useState(0);
  const [presentmonthData, setPresentmonthData] = useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  React.useEffect(() => {
    setSelectedMonth(yearMonth[0]);
    setDummy(!dummy);
    // eslint-disable-next-line
  }, [months]);
  React.useEffect(() => {
    let aa = allweaks.map(
      (val, i) =>
        moment(val.week.split("/")[0], "DD-MM-YYYY").format("MMMM yyyy") ===
          selectedMonth ||
        moment(val.week.split("/")[1], "DD-MM-YYYY").format("MMMM yyyy") ===
          selectedMonth
    );
    setValue(0);
    // console.log(aa, "selectedMonth");
    for (let i = 0; i < aa.length; i++) {
      if (aa[0]) break;
      if (aa[i] || aa[i + 1]) {
        setIndexxx(i + 1);
        setDummy(!dummy);
        break;
      }
    }
    let bb = [];
    for (let index = 0; index < allweaks.length; index++) {
      if (aa[index]) {
        bb.push(allweaks[index]);
      }
    }
    setPresentmonthData(bb);
  }, [selectedMonth]);
  React.useEffect(() => {
    let sss = Setdata(allweaks, s);
    setAllweaks(sss);
  }, []);
  const Setdata = (a, b) => {
    for (let i = 0; i < a.length; i++) {
      for (let j = 0; j < b.length; j++) {
        if (a[i].week === b[j].week) {
          a[i]["topics"] = b[j]["total_topics"];
        }
      }
    }
    return a;
  };
  return (
    <>
      {allweaks && allweaks.length > 0 ? (
        <section className="mg-b-20 mg-t-30 upload-section">
          <label className="h-l">Weekly Topics</label>
          <div className="row mg-t-20 month-add">
            <div className="col-md-5 col-xl-5 col-lg-5 col-12 form-group">
              <FormControl fullWidth className="month-field-ali">
                <InputLabel id="month-select-helper-label">
                  Select Month
                </InputLabel>
                <Select
                  labelId="month-select-helper-label"
                  id="month-select-helper"
                  value={`${selectedMonth}`}
                  label="Select Month"
                  onChange={(e) => setSelectedMonth(e?.target?.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {yearMonth &&
                    yearMonth?.map((val, i) => (
                      <MenuItem value={val} key={i}>
                        {val}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-3 add-m-t">
              <button
                type="button"
                onClick={() => addWeekTopics(indexxx, value)}
                className="btn-fill-lg bg-blue-dark btn-hover-yellow add-in-btn radius-30"
              >
                + Add More Topics
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 w-topics">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {allweaks &&
                    allweaks?.map((val, i) =>
                      moment(val.week.split("/")[0], "DD-MM-YYYY").format(
                        "MMMM yyyy"
                      ) === selectedMonth ||
                      moment(val.week.split("/")[1], "DD-MM-YYYY").format(
                        "MMMM yyyy"
                      ) === selectedMonth ? (
                        <Tab
                          label={`week-${i + 1}(${moment(
                            val.week.split("/")[0],
                            "DD-MM-YYYY"
                          ).format("DD")} - ${moment(
                            val.week.split("/")[1],
                            "DD-MM-YYYY"
                          ).format("DD")})`}
                          key={i}
                          {...a11yProps(i)}
                        />
                      ) : null
                    )}
                </Tabs>
              </Box>
              {presentmonthData &&
                presentmonthData?.map((val, i) => (
                  <TabPanel value={value} index={i}>
                    <div className="row topic-header">
                      <div className="col-md-3">Topic Name</div>
                      <div className="col-md-2 p-l">Home Work</div>
                      <div className="col-md-2">Pdf Upload</div>
                      <div className="col-md-3">Video</div>
                      <div className="col-md-2">Actions</div>
                    </div>
                    {val?.topics?.map((item, indx) =>
                      item.state !== "REMOVE" ? (
                        <div className="row topic-content" key={indx}>
                          <div className="col-md-3">
                            <TextField
                              id="standard-basic"
                              placeholder="Topic Name"
                              variant="standard"
                              name="topicName"
                              value={item.topicName}
                              onChange={(e) =>
                                handleTopicChange(e, indexxx + i, indx)
                              }
                            />
                          </div>
                          <div className="col-md-2">
                            <Checkbox
                              {...label}
                              checked={
                                item.homework === "true" ||
                                item.homework === true
                                  ? true
                                  : false
                              }
                              name="homework"
                              onChange={(e) =>
                                handleTopicChange(e, indexxx + i, indx)
                              }
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </div>
                          <div className="col-md-2 upl-icn pointer d-flex align-items-center">
                            {item.pdfName ? (
                              <>
                                <p>{item.pdfName}</p>
                                <i
                                  className="fas fa-times"
                                  style={{ color: "red", fontSize: "1.6rem" }}
                                  onClick={(e) => {
                                    handleTopicPdfChange(e, indexxx + i, indx);
                                    // handleTopicChange(e, indexxx + i, indx);
                                    // item.pdf_name = "";
                                  }}
                                ></i>
                              </>
                            ) : (
                              <i
                                className="fas fa-cloud-upload-alt"
                                onClick={() =>
                                  document
                                    .getElementById(`${"filel" + indx}`)
                                    .click()
                                }
                              >
                                <input
                                  type="file"
                                  name="pdf"
                                  id={"filel" + indx}
                                  accept="application/pdf"
                                  required
                                  className="donotshow"
                                  onChange={(e) => {
                                    handleTopicChange(e, indexxx + i, indx);
                                  }}
                                />
                              </i>
                            )}{" "}
                            {/* {pdfFileError && !item.pdfName && (
                              <div
                                className="text-danger"
                                style={{ margin: "0" }}
                              >
                                {pdfFileError}
                              </div>
                            )} */}
                          </div>
                          <div className="col-md-3">
                            <TextField
                              id="standard-basic"
                              placeholder="Video Link"
                              variant="standard"
                              name="video"
                              value={item.video}
                              onChange={(e) =>
                                handleTopicChange(e, indexxx + i, indx)
                              }
                            />
                          </div>
                          <div className="col-md-2 action-btns">
                            <Tooltip title="Delete">
                              <button
                                type="button"
                                onClick={() => {
                                  handleTopicDelete(indexxx + i, indx);
                                }}
                              >
                                <i className="far fa-trash-alt"></i>
                              </button>
                            </Tooltip>
                            <Tooltip title="Reset">
                              <button
                                type="button"
                                className="r-btn"
                                onClick={() => {
                                  handleTopicReset(indexxx + i, indx);
                                }}
                              >
                                <i className="fas fa-sync-alt"></i>
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      ) : null
                    )}
                  </TabPanel>
                ))}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default Schedule;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // alert(value);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
