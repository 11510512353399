import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Axios from "../../utilities/axios";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";

const Homeworks = () => {
  const [rows, setRows] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [clases, setClases] = React.useState([]);
  const [selectedClass, setSelectedClass] = React.useState("");
  const [selectedCourse, setSelectedCourse] = React.useState("");
  React.useEffect(() => {
    CallApi(selectedCourse, selectedClass);
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    CallApi();
    //eslint-disable-next-line
  }, [selectedCourse, selectedClass]);

  const CallApi = () => {
    const uid = localStorage.getItem("uid");
    Axios.get(
      `teacher/get_teacher_homework/${uid}/${
        selectedCourse !== "" ? selectedCourse : 0
      }/${selectedClass !== "" ? selectedClass : 0}`
    )
      .then((res) => {
        // console.log(res.data, "res.data");
        setRows(res.data.data);
        setCourses(res.data.courses);
        setClases(res.data.classes);
        // dispatch({
        //   type: AUTH_TYPES.SELECTED_STUDENTS,
        //   payload: res?.data?.data,
        // });
        // setRows(
        //   authState &&
        //     authState.selectedStudents.length > 0 &&
        //     authState?.selectedStudents
        // );
      })
      .catch((err) => {
        console.log(err);
      });
    //   navigator.clipboard.writeText(JSON.stringify(res?.data));
  };
  // console.log(authState.selectedStudents, "authState");
  //generate random number function
  // const generateRandomNumber = () => {
  //   return Math.floor(Math.random() * 100);
  // };

  const columns = [
    { field: "id", headerName: "ID Number", width: 120, hide: true },
    {
      field: "week_name",
      headerName: "Date",
      width: 140,
    },
    { field: "fullname", headerName: "Sudent Name", width: 200 },
    {
      field: "course_name",
      headerName: "Course Name",
      width: 190,
    },
    {
      field: "class_name",
      headerName: "Class Name",
      width: 190,
    },
    {
      field: "home_work",
      headerName: "PDF",
      renderCell: ButtonPDF,
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
    },
    {
      field: "address",
      headerName: "Actions",
      width: 190,
      renderCell: ButtonCell,
      type: "actions",
    },
  ];
  return (
    <div>
      {rows ? (
        <div className="card height-auto">
          <div className="card-body">
            <div className="heading-layout1 row">
              <div className="item-title col-md-4">
                <h4>Homework List</h4>
              </div>
              <div className="item-title col-md-4">
                <FormControl fullWidth>
                  <InputLabel id="course-duration-select-helper-label">
                    Select Course
                  </InputLabel>
                  <Select
                    labelId="course-duration-select-helper-label"
                    id="course-duration-select-helper"
                    value={selectedCourse}
                    label="Course Duration"
                    onChange={(e) => setSelectedCourse(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {courses?.map((item) => (
                      <MenuItem value={`${item?.course_id}`}>
                        {item?.course_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="item-title col-md-4">
                <FormControl fullWidth>
                  <InputLabel id="course-duration-select-helper-label">
                    Select Class
                  </InputLabel>
                  <Select
                    labelId="course-duration-select-helper-label"
                    id="course-duration-select-helper"
                    value={selectedClass}
                    label="Course Duration"
                    onChange={(e) => setSelectedClass(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {clases?.map((item) => (
                      <MenuItem value={`${item?.class_id}`}>
                        {item?.class_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div
              className="table-responsive"
              style={{ height: 650, width: "100%" }}
            >
              <DataGrid
                components={{
                  Toolbar: GridToolbar,
                }}
                rows={rows}
                columns={columns}
                pageSize={10}
              />
            </div>
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default Homeworks;

const ButtonCell = (props) => {
  const callRight = (p) => {
    // console.log(p, "p");
    let row = p.row;
    row.status = "accepted";
    let uid = localStorage.getItem("uid");
    row.teacher_id = uid;
    Axios.post("teacher/accept_homework", row)
      .then((res) => {
        // console.log(res.data, "res");
        if (res.data.status === 200) {
          toast("HomeWork Accepted", {
            position: "top-center",
            type: "success",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          props.api.setRowData(res?.data?.result);
          // toast.success("Homework Corrected");
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const callWrong = (p) => {
    // alert("right");
    // console.log(p.row, "p");
    let row = p.row;
    row.status = "rejected";
    let uid = localStorage.getItem("uid");
    row.teacher_id = uid;
    Axios.post("teacher/accept_homework", row)
      .then((res) => {
        // console.log(res.data, "res");
        if (res.data.status === 200) {
          toast("HomeWork rejected", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            type: "error",
            theme: "colored",
            draggable: true,
            progress: undefined,
          });
          // props.api.setRows(row);
          // toast.success("Homework Corrected");
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  return (
    <div>
      {props.row.status === "pending" ? (
        <>
          <i
            className="fas fa-check text-success"
            onClick={() => callRight(props)}
          ></i>
          <i
            className="fas fa-times text-danger"
            onClick={() => callWrong(props)}
          ></i>
        </>
      ) : null}
    </div>
  );
};

const ButtonPDF = (props) => {
  return (
    <a href={props.row.home_work} target="_blank">
      <i className="far fa-file-pdf text-success"></i>
    </a>
  );
};
