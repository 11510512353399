import React from "react";
// import Graphcomp from "../commonComponents/Graphcomp";
import Mycard from "../commonComponents/Mycard";
import { Doughnut } from "react-chartjs-2";
import { useHistory } from "react-router";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Axios from "../../utilities/axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Chart from "react-apexcharts";

const Tdashboard = () => {
  let history = useHistory();
  // console.log(history.location.pathname);
  const [values, setValues] = React.useState(null);
  const [months, setMonths] = React.useState([]);
  const [counts, setCounts] = React.useState([]);
  React.useEffect(() => {
    const user = localStorage.getItem("token");
    if (!user) {
      history.push("/login");
    } else {
      callAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callAPI = async () => {
    const uid = localStorage.getItem("uid");
    await Axios.get(`dashboard/teacher_dashboard/${uid}`)
      .then((res) => {
        let apiRes = res?.data;
        setValues(apiRes?.data?.length > 0 ? apiRes.data : []);
        // console.log(apiRes, "API responce");
        setMonths(apiRes?.months[1].length > 0 ? apiRes.months[1] : []);
        setCounts(apiRes?.months[0].length > 0 ? apiRes.months[0] : []);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const data1 = {
    labels: ["male", "female", "not selected"],
    datasets: [
      {
        label: "Gender",
        data: [
          values && values[0] && values[0]?.[0].male_count,
          values && values[0] && values[0]?.[0].female_count,
          values && values[0] && values[0]?.[0].not_selected,
        ],
        backgroundColor: ["#417DFC", "#FFAA01", "tomato"],
        borderColor: ["#417DFC", "#FFAA01", "tomato"],
        borderWidth: 1,
      },
    ],
  };
  const data2 = {
    labels: ["1-15", "16-30", "31-45", "46-90", "Not Selected"],
    datasets: [
      {
        label: "Age",
        data: [
          values && values[0]?.[0].age_01to15,
          values && values[0]?.[0].age_16to30,
          values && values[0]?.[0].age_31to45,
          values && values[0]?.[0].age_46to100,
          values && values[0]?.[0].not_selected_age,
        ],
        backgroundColor: ["#417DFC", "#FFAA01", "red", "aqua", "#47dd9c"],
        borderColor: ["#417DFC", "#FFAA01", "red", "aqua", "#47dd9c"],
        borderWidth: 1,
      },
    ],
  };
  const data3 = {
    labels: ["India", "Others", "UK", "USA"],
    datasets: [
      {
        label: "Region",
        data: values && values?.[1] && values[1],
        backgroundColor: ["#417DFC", "#FFAA01", "#FE1EFA", "skyblue"],
        borderColor: ["#417DFC", "#FFAA01", "#FE1EFA", "skyblue"],
        borderWidth: 1,
      },
    ],
  };
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const myCardData = [
    {
      iClass: "flaticon-mortarboard text-orange",
      name: "Total courses",
      url: "/All Courses",
      number: `${values && values[0] && values[0]?.[0].total_courses}`,
    },
    {
      iClass: "flaticon-classmates text-green",
      name: "Students",
      url: "/Student List",
      number: `${
        values && values[0]?.[0]?.total_students !== undefined
          ? values[0][0].total_students
          : "0"
      }`,
    },
    {
      iClass: "flaticon-couple text-orange",
      name: "Association Duration",
      number: `${values && values[0] && values[0]?.[0].associated_from}`,
    },

    {
      iClass: "flaticon-multiple-users-silhouette text-blue",
      name: "Vacant Students",
      number: `${
        (values && values[0] && values[0]?.[0].total_teachers) || "0"
      } `,
    },
  ];
  const series = [
    {
      name: "Students Joined",
      data: counts,
    },
  ];
  const options = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: "light",
      palette: "palette1",
      monochrome: {
        enabled: true,
        color: "#FEB019",
        shadeTo: "light",
        shadeIntensity: 0.65,
      },
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "Student Enrolled",
      align: "left",
    },
    subtitle: {
      text: "Students who registered for courses in last 6 months",
      align: "left",
    },
    labels: months,
    xaxis: {
      type: "number",
    },
    yaxis: {
      opposite: false,
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  return (
    <>
      {values ? (
        <div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="row gutter-20">
                <div className="col-sm-12 col-md-12 col-lg-12 carou-card">
                  <Carousel responsive={responsive} infiniteLoop={true}>
                    {myCardData.map((item, index) => (
                      <div
                        key={index}
                        className={`carou-slider ${
                          typeof item.url !== "undefined" ? "pointer" : ""
                        }`}
                        onClick={() => history.push(item.url)}
                      >
                        <Mycard
                          iClass={item.iClass}
                          name={item.name}
                          number={item.number}
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-9 col-lg-9">
              <div className="card">
                <div className="card-body">
                  <Chart
                    series={series}
                    options={options}
                    type="area"
                    height={350}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3">
              <div className="dashboard-summery-one">
                <div className="row">
                  <div className="item-title">
                    <h5 style={{ margin: "0" }}>Gender</h5>
                  </div>
                  <Doughnut data={data1} />
                </div>
                <div className="row">
                  <div className="item-title">
                    <h5 style={{ margin: "0" }}>Ages</h5>
                  </div>
                  <Doughnut data={data2} />
                </div>
                <div className="row">
                  <div className="heading-layout1">
                    <div className="item-title">
                      <h5 style={{ margin: "0" }}>Region</h5>
                    </div>
                  </div>
                  <Doughnut data={data3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </>
  );
};
export default Tdashboard;
