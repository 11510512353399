import React from "react";
import Axios from "../../utilities/axios";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
const Changepassword = () => {
  let history = useHistory();
  const [object, setObject] = React.useState({
    old_password: "",
    new_password: "",
  });
  const [values, setValues] = React.useState({
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
    showOldPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showOldPassword: !values.showOldPassword,
    });
  };
  const handleClickShowCPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowCCPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownCPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownCCPassword = (event) => {
    event.preventDefault();
  };
  const changePassword = () => {
    let user_id = localStorage.getItem("uid");
    let dataSending = object;
    if (
      object.old_password === "" ||
      object.new_password === "" ||
      values.confirmPassword === ""
    ) {
      toast("Please enter all fields", {
        position: "top-center",
        type: "info",
        theme: "colored",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (object.new_password !== values.confirmPassword) {
      toast("Passwords does not match", {
        position: "top-center",
        type: "warning",
        theme: "colored",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let copy = { ...object };
      let p = copy.old_password;
      let r = copy.new_password;
      dataSending.old_password = CryptoJS.AES.encrypt(p, "rushi").toString();
      dataSending.new_password = CryptoJS.AES.encrypt(r, "rushi").toString();
      dataSending.user_id = user_id;
      Axios.post("user_details/change_password", dataSending)
        .then((res) => {
          if (res.data.message !== "failed") {
            toast("Password changed successfully", {
              position: "top-center",
              type: "success",
              theme: "colored",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setObject({
              old_password: "",
              new_password: "",
            });
            setValues({
              ...values,
              showOldPassword: false,
              showPassword: false,
              showConfirmPassword: false,
            });
            history.push("/profile");
          } else {
            toast(`${res?.data?.error}`, {
              position: "top-center",
              type: "info",
              theme: "colored",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          toast("Something went wrong", {
            position: "top-center",
            type: "error",
            theme: "colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setObject({
            old_password: "",
            new_password: "",
          });
          setValues({
            ...values,
            showOldPassword: false,
            showPassword: false,
            showConfirmPassword: false,
          });
          //     console.log(err);
        });
    }
  };
  return (
    <div className="card auto-height" style={{ height: "30rem" }}>
      <div className="card-body">
        <div className="row m-4">
          <div className="col-md-12">
            {" "}
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="password">Old Password</InputLabel>
              <OutlinedInput
                id="password"
                type={values.showOldPassword ? "text" : "password"}
                value={object.old_password}
                onChange={(e) =>
                  setObject({ ...object, old_password: e.target.value })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showOldPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Old Password"
              />
            </FormControl>
          </div>
        </div>
        <div className="row m-4">
          <div className="col-md-6">
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="password">New Password</InputLabel>
              <OutlinedInput
                id="old password"
                type={values.showPassword ? "text" : "password"}
                value={object?.new_password}
                onChange={(e) =>
                  setObject({ ...object, new_password: e.target.value })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCPassword}
                      onMouseDown={handleMouseDownCPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
            </FormControl>
          </div>
          <div className="col-md-6">
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="password">Confirm New Password</InputLabel>
              <OutlinedInput
                id="cpassword"
                type={values.showConfirmPassword ? "text" : "password"}
                value={values.confirmPassword}
                onChange={(e) =>
                  setValues({ ...values, confirmPassword: e.target.value })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCCPassword}
                      onMouseDown={handleMouseDownCCPassword}
                      edge="end"
                    >
                      {values.showConfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm New Password"
              />
            </FormControl>
          </div>
        </div>
        <div className="row m-4">
          <div className="col-md-4">
            <button
              type="button"
              onClick={() => changePassword()}
              className="btn-fill-lg bg-blue-dark btn-hover-yellow radius-30"
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Changepassword;
