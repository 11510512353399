import React, { useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import Tablecomps from "./Tablecomps";
import Axios from "../../utilities/axios";
import { toast } from "react-toastify";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import moment from "moment";
// import swal from "sweetalert";
import moment from "moment-timezone";
import { useHistory } from "react-router";
// useHistory
const Viewattendence = () => {
  const history = useHistory();
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [courses, setCourses] = React.useState([]);
  const [clases, setClases] = React.useState([]);
  const [date, setDate] = React.useState(null);
  const [selectedClass, setSelectedClass] = React.useState("");
  const [selectedCourse, setSelectedCourse] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [dummy, setDummy] = React.useState([]);
  //   const [selectedClassId, setSelectedClassId] = React.useState("");
  //   const [viewAttendanceClicked, setViewAttendanceClicked] =
  //     React.useState(false);
  //     alert(date);
  //   console.log(date);
  React.useEffect(() => {
    setLoading(true);
    CallApi();
    //eslint-disable-next-line
  }, [selectedCourse, selectedClass]);

  useEffect(() => {
    CallApi();
    //eslint-disable-next-line
  }, []);
  //   console.log(selectedClassId, "selectedClassId");
  const CallApi = (id = 0, cid = 0) => {
    const uid = localStorage.getItem("uid");
    Axios.get(
      `teacher/get_attendence_for_teacher/${uid}/${
        selectedCourse !== "" ? selectedCourse : 0
      }/${selectedClass !== "" ? selectedClass : 0}`
    )
      .then((res) => {
        let ress = res?.data;
        setDummy(ress?.data?.length > 0 ? ress?.data : []);
        setRows(ress?.data?.length > 0 ? ress?.data : []);
        setCourses(ress.courses);
        setClases(ress.classes);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //   const handleAttendance = async () => {
  //     if (selectedCourse === "") {
  //       toast.error("Please select course");
  //       window.scrollTo(0, 0);
  //       return;
  //     }
  //     if (selectedClass === "") {
  //       toast.error("Please select class");
  //       window.scrollTo(0, 0);
  //       return;
  //     }
  //     if (!date) {
  //       toast.error("Please select Date");
  //       window.scrollTo(0, 0);
  //       return;
  //     }
  //     await swal({
  //       title: "Are you sure?",
  //       text: `Please confirm you have selected (${moment(date).format(
  //         "DD-MMM-YYYY"
  //       )}) as the date for attendance`,
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: true,
  //     }).then((willDelete) => {
  //       if (willDelete) {
  //         for (let i = 0; i < rows.length; i++) {
  //           for (let i = 0; i < selected.length; i++) {
  //             if (rows[i].id === selected[i].id) {
  //               rows[i].attendence = "present";
  //             } else {
  //               rows[i].attendence = "absent";
  //             }
  //           }
  //         }
  //         let newData = JSON.parse(JSON.stringify(rows));
  //         let uid = localStorage.getItem("uid");
  //         newData.map((item) => {
  //           item.student_id = item.id;
  //           item.teacher_id = uid;
  //           item.course_id = selectedCourse;
  //           item.class_id = selectedClassId;
  //           delete item.address;
  //           delete item.associated_from;
  //           delete item.course_name;
  //           delete item.dob;
  //           delete item.email_id;
  //           delete item.fullname;
  //           delete item.id;
  //           delete item.phone_number;
  //           item.date = date;
  //         });
  //         //   console.log(newData, "CCCCCCCCCCCCCCCCCC");
  //         //   return;
  //         let data = { attendence: newData };
  //         Axios.post("student/student_attendence", data)
  //           .then((res) => {
  //             if (res?.data?.status === 200) {
  //               //   setRows(res.data.data);
  //               if (res.data.message == "Please select correct date") {
  //                 toast.error(res.data.message);
  //                 window.scrollTo(0, 0);
  //                 return;
  //               } else if (res.data.message == "Attendence already submited") {
  //                 toast.error(res.data.message);
  //                 window.scrollTo(0, 0);
  //                 return;
  //               } else {
  //                 toast.success(res.data.message, {
  //                   position: "top-center",
  //                   autoClose: 5000,
  //                   hideProgressBar: false,
  //                   closeOnClick: true,
  //                   pauseOnHover: true,
  //                   draggable: true,
  //                   progress: undefined,
  //                 });
  //                 setSelectedCourse("");
  //                 setSelectedClass("");
  //                 setSelectedClassId("");
  //                 setSelected([]);
  //                 setDate(null);
  //                 CallApi();
  //               }
  //             }
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       }
  //     });
  //     //     return;
  //   };

  const columns = [
    { field: "id", headerName: "ID Number", width: 120, hide: true },
    { field: "fullname", headerName: "Student Name", width: 190 },
    {
      field: "course_name",
      headerName: "Course Name",
      width: 200,
    },
    {
      field: "email_id",
      headerName: "Email id",
      width: 210,
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      width: 190,
    },
    {
      field: "attendence",
      headerName: "Attendance",
      width: 240,
    },
    {
      field: "attendence_date",
      headerName: "Attendance Date",
      width: 200,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 200,
      type: "number",
    },
  ];

  //   const col = [...columns];
  const handleViewAttendance = () => {
    history.push("/attendance");
    //     setViewAttendanceClicked(!viewAttendanceClicked);
    //     if (!viewAttendanceClicked) {
    //       const uid = localStorage.getItem("uid");
    //       Axios.get(
    //         `teacher/get_attendence_for_teacher/${uid}/${
    //           selectedCourse !== "" ? selectedCourse : 0
    //         }/${selectedClassId !== "" ? selectedClassId : 0}`
    //       )
    //         .then((res) => {
    //           let ress = res?.data;
    //           setRows(ress?.data?.length > 0 ? ress?.data : []);
    //           setCourses(ress.courses);
    //           setClases(ress.classes);
    //           setLoading(false);
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //     } else {
    //       CallApi();
    //     }
  };
  React.useEffect(() => {
    let a = dummy?.filter((row) =>
      JSON.stringify(row.attendence_date)
        .toLowerCase()
        .match(date.toLowerCase())
    );
    setRows(a.length > 0 ? a : []);
    //eslint-disable-next-line
  }, [date]);
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <div className="heading-layout1 row">
            <div className="item-title col-md-3">
              <button
                onClick={() => handleViewAttendance()}
                className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
              >
                Take Attendance
              </button>
            </div>
            <div className="item-title col-md-3">
              <FormControl fullWidth>
                <InputLabel id="course-duration-select-helper-label">
                  Select Course
                </InputLabel>
                <Select
                  labelId="course-duration-select-helper-label"
                  id="course-duration-select-helper"
                  value={selectedCourse}
                  label="Course Duration"
                  onChange={(e) => setSelectedCourse(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {courses?.map((item) => (
                    <MenuItem value={`${item?.course_id}`}>
                      {item?.course_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="item-title col-md-3">
              <FormControl fullWidth>
                <InputLabel id="course-duration-select-helper-label">
                  Select Class
                </InputLabel>
                <Select
                  labelId="course-duration-select-helper-label"
                  id="course-duration-select-helper"
                  value={selectedClass}
                  label="Course Duration"
                  onChange={(e) => {
                    //   let idd = clases.filter(
                    //     (item) => item.id == e.target.value
                    //   );
                    //   //   console.log(idd, "idd");
                    setSelectedClass(e.target.value);
                    //   setSelectedClassId(idd[0]?.class_id);
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {clases?.map((item) => (
                    <MenuItem value={`${item.class_id}`}>
                      {item?.class_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="item-title col-md-3">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Select Date"
                  value={date}
                  // mask="__-__-____"
                  mask="dd-MM-yyyy"
                  inputFormat="dd-MMM-yyyy"
                  // disablePast={true}
                  onChange={(newValue) => {
                    //   let mm = moment(newValue).format("DD-MMM-YYYY");
                    setDate(moment(newValue).format("DD-MMM-YYYY"));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>
          {/* {!viewAttendanceClicked ? (
            <DataGrid
              rows={rows}
              columns={columns}
              loading={loading}
              selected={selected}
              setSelected={setSelected}
              checkboxSelection
              disableSelectionOnClick
              autoHeight
              components={{
                Toolbar: GridToolbar,
              }}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = rows.filter((row) =>
                  selectedIDs.has(row.id)
                );
                setSelected(selectedRows);
              }}
            />
          ) : ( */}
          <DataGrid
            rows={rows}
            columns={columns}
            loading={loading}
            selected={selected}
            setSelected={setSelected}
            //   checkboxSelection
            //   disableSelectionOnClick
            autoHeight
            components={{
              Toolbar: GridToolbar,
            }}
          />
          {/* )} */}
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-3">
          <button
            type="button"
            onClick={() => handleAttendance()}
            className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
          >
            Submit Attendance
          </button>
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default Viewattendence;
