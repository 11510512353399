import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
const Breadcubs = ({ pre }) => {
  const history = useHistory();
  React.useEffect(() => {}, [history.location.pathname]);
  let p = history.location.pathname.replace("/", "");
  const [role, setRole] = React.useState(null);
  let a = p.split("/");
  React.useEffect(() => {
    const Hrole = localStorage.getItem("role");
    if (Hrole === "courseadmin") setRole("Course Admin");
    else if (Hrole === "admin") setRole("Account Admin");
    else setRole("Teacher");
  }, []);
  return (
    <>
      {/* Breadcubs Area Start Here */}
      <div className="breadcrumbs-area">
        <h3>{role} Dashboard</h3>
        <ul>
          <li>
            <Link
              to={
                role === "Course Admin"
                  ? "/Course Admin Dashboard"
                  : role === "Account Admin"
                  ? "/Account Admin Dashboard"
                  : role === "Teacher"
                  ? "/Teacher Dashboard"
                  : "/login"
              }
              exact="true"
            >
              Home
            </Link>
          </li>
          {pre ? (
            <li>
              <Link to={`/${pre}`} exact="true">
                {pre}
              </Link>
            </li>
          ) : (
            ""
          )}
          <li>{a[0]}</li>
        </ul>
      </div>
      {/* Breadcubs Area End Here */}
    </>
  );
};

export default Breadcubs;
