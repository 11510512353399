import { Button, TextField } from '@mui/material'

import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import image from '../../assests/img/books.JPG'

const SpeakerModel = ({
    modal,
    toggle,
    className,
    editInx,
    speaker,
    handleChangeSpeaker,
    updateSpeaker,
    resetSpeaker,
    addSpeaker
}) => {
 
  return (
    <Modal isOpen={modal} toggle={toggle} className='speaker_model_con'> 
      <ModalHeader toggle={toggle}>
        {/* {editInx === null ? "Add " : "Edit "}Glimpse */}
        Add Speaker
      </ModalHeader>
      <ModalBody>
        <form className="new-added-form">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-8 col-8">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Name *"
                    variant="outlined"
                    name="speaker_name"
                     value={speaker.speaker_name}
                     onChange={(e) => handleChangeSpeaker(e)}
                  />
                </div>
              
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-4 col-4"
             onClick={() => document.getElementById("iffft").click()}
            >
              <img
                className="img-fluid pointer"
                alt="not yet loaded"
                // height="150px"
                // width="150px"
                src={speaker.speaker_image}
                style={{
                  width: "125px",
                  height: "125px",
                  objectFit: "cover",
                  borderRadius: "100%",
                  border: "1px solid #ccc",
                }}
              />
              <i
                className="fas fa-cloud-upload-alt"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "101px",
                  left: "105px",
                  fontSize: "2rem",
                }}
              ></i>
              <input
                type="file"
                accept="image/*"
                id="iffft"
                name="speaker_iamge"
                // style={{ display: "none" }}
                className="d-none"
                onChange={(e) => handleChangeSpeaker(e)}
              />
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        {editInx === null ? ( 
          <div>
            <Button
              color="primary"
              onClick={(e) => addSpeaker(e)}
              className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
            >
              Add
            </Button>{" "}
            &nbsp;&nbsp;
            <Button
              color="secondary"
              onClick={resetSpeaker}
              className="btn-fill-lg bg-blue-dark btn-hover-yellow"
            >
              Reset
            </Button>
          </div>
        ) : ( 
          <Button
            color="primary"
            className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
            onClick={(e) => updateSpeaker(e)}
          >
            Update
          </Button>
         )} 
      </ModalFooter>
    </Modal>
  )
}

export default SpeakerModel