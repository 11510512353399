import React from 'react'
import { useHistory } from 'react-router'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Axios from '../utilities/axios'
import swal from 'sweetalert'
import { toast } from 'react-toastify'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";


const Somelist = ({ a }) => {
  const [rows, setRows] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [pageSize, setPageSize] = React.useState(30)
  const [courseStatus, setcourseStatus] = React.useState({
    status: "active",
  });


  React.useEffect(() => {
    fun()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // const fun = async () => {
  //   setLoading(true)
  //   let p =
  //     a === 'Date of birth'
  //       ? '/student/get_all_students'
  //       : '/teacher/get_all_teachers'
  //   try {
  //     let res = await Axios.get(p)
  //     let s = await res?.data?.data
  //     await setRows(s)
  //     await setLoading(false)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }


  const fun = async () => {
    setLoading(true);

    let p;
    if (a === 'Date of birth') {
      // Make a POST request for /student/get_all_students
      p = '/student/get_all_students';
      try {
        const res = await Axios.post(p, { course_status: courseStatus.status });
        const s = res?.data?.data;
        setRows(s);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    } else {
      // Make a GET request for /teacher/get_all_teachers
      p = '/teacher/get_all_teachers';
      try {
        const res = await Axios.get(p);
        const s = res?.data?.data;
        setRows(s);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 180, hide: true },
    {
      field: 'user_id',
      headerName: a === 'Date of birth' ? 'Student ID' : 'Teacher ID',
      width: 180,
    },
    // course_id
    { field: 'fullname', headerName: 'Name', width: 140 },
    {
      field: 'course_name',
      headerName: 'Course',
      width: 180,
    },
    {
      field: 'dob',
      headerName: 'Date of birth',
      width: 180,
    },
    {
      field: 'register_at',
      headerName: 'Register Date',
      width: 200,
    },
    {
      field: 'course_name',
      headerName: 'Course Name',
      width: 200,
    },
    {
      field: 'language',
      headerName: 'Language',
      width: 200,
    },
    {
      field: 'teacher',
      headerName: 'Teacher Name',
      width: 200,
    },
    {
      field: 'class_name',
      headerName: 'Class Name',
      width: 200,
    },
    {
      field: 'slot_time',
      headerName: 'Class Time',
      width: 200,
    },
    {
      field: 'phone_number',
      headerName: 'Mobile',
      width: 185,
    },
    {
      field: 'email_id',
      headerName: 'E-mail Address',
      width: 200,
    },
    // {
    //   field: 'address',
    //   headerName: 'Address',
    //   width: 200,
    // },
    // {
    //   field: 'amount',
    //   headerName: 'Amount',
    //   width: 200,
    // },
    // {
    //   field: 'pay_status',
    //   headerName: 'Payment Status',
    //   width: 200,
    // },
    // {
    //   field: 'payment_date',
    //   headerName: 'Payment Date',
    //   width: 200,
    // },
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   width: 350,
    //   sortable: false,
    //   renderCell: RenderDetailsButton,
    //   disableClickEventBubbling: true,
    // },
    {
      field: 'address',
      headerName: 'Address',
      width: 350,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 200,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 200,
    },
    {
      field: 'pincode',
      headerName: 'Pincode',
      width: 200,
    },
    {
      field: 'region',
      headerName: 'Region',
      width: 200,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 200,
    },
    {
      field: 'pay_status',
      headerName: 'Payment Status',
      width: 200,
    },
    {
      field: 'payment_date',
      headerName: 'Payment Date',
      width: 200,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 350,
      sortable: false,
      renderCell: RenderDetailsButton,
      disableClickEventBubbling: true,
    },
  ]
  columns[3].headerName = a === 'Date of birth' ? 'Date Of Birth' : 'Students'
  columns[0].headerName = a === 'Date of birth' && 'Student ID'
  columns[3].field = a === 'Date of birth' ? 'dob' : 'students'
  // columns[4].headerName = a === 'Date of birth' ? 'Address' : 'Associated from'
  // columns[4].field = a === 'Date of birth' ? 'address' : 'associated_from'
  if (a != 'Date of birth') {
    columns.splice(8, 1)
    columns.splice(8, 1)
    columns.splice(8, 1)
    columns.splice(8, 1)
    columns.splice(8, 1)
    columns.splice(8, 1)
    columns.splice(8, 1)
    columns.splice(8, 1)
    columns.splice(8, 1)
    columns.splice(8, 1)
    columns.splice(8, 1)

    columns[3].headerName = 'Students'
    columns[3].field = 'students'


    columns[4].headerName = 'Associated from'
    columns[4].field = 'associated_from'
  } else {
    //removing dob
    columns.splice(4, 1)

    //interchangeing postion
    let temp = columns[3];
    columns[3] = columns[4];
    columns[4] = temp;

  }
  if (loading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
          <Typography variant="h6" sx={{ marginTop: '20px' }}>
            Please Wait...
          </Typography>
        </div>
      </Box>
    )
  }


  const courseChange = async (event) => {
    setcourseStatus({ status: event.target.value });
    let p;
    p = '/student/get_all_students';
    try {
      const res = await Axios.post(p, { course_status: event.target.value });
      const s = res?.data?.data;
      setRows(s);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }

  };

  return (
    <>

      {rows && (
        <div className="card height-auto">

          <div className="card-body">
          <div className="row mb-4">
            <div className="col-md-8"></div>
            <div className="col-md-4 col-xl-4 col-lg-4  form-group">
              <FormControl fullWidth>
                <InputLabel id="course-duration-select-helper-label">
                  Select Category
                </InputLabel>
                <Select
                  labelId="course-duration-select-helper-label"
                  id="course-duration-select-helper"
                  // value={details?.course_duration}
                  value={courseStatus?.status} // Set the default value to "active"
                  // value={"active"} // Set the default value to "active"

                  label="Course Duration"
                  // disabled={status}
                  onChange={courseChange}
                // disabled={
                //   history.location.pathname.includes("/editcourse/")
                //     ? status
                //     : false
                // }
                >
                  {/* <MenuItem value="">
                    <em>None</em>
                  </MenuItem> */}
                  <MenuItem value={"active"}>Active Courses</MenuItem>
                  <MenuItem value={"inactive"}>Inactive Courses</MenuItem>
                  <MenuItem value={"all"}>All Courses</MenuItem>
                  {/* <MenuItem value={"yearly"}>Yearly</MenuItem> */}
                </Select>
              </FormControl>
            </div>
            </div>
            <div className="table-responsive">
              <DataGrid
                rows={rows}
                columns={columns}
                autoHeight={true}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
        
          </div>
        </div>
      )}
    </>
  )
}

export default Somelist

const RenderDetailsButton = (params) => {
  let history = useHistory()
  const onDelete = (params) => {
    if (history.location.pathname.includes('student')) {
      swal({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          let uid = localStorage.getItem('uid')
          let role = localStorage.getItem('role')
          let data = {
            student_id: `${params.row.user_id}`,
            user_id: uid,
            role,
          }
          Axios.post('student/delete_student', data)
            .then((res) => {
              if (res.data.message === 'not allowed to delete') {
                swal('Your Not Allowed to Delete!', {
                  icon: 'warning',
                })
              } else {
                swal('Student has been deleted!', {
                  icon: 'success',
                })
                res?.data?.data?.map((item, index) => {
                  item.uid = item.id
                  item.id = index + 1
                  return item
                })
                params.api.setRows(res.data.data)
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
    } else {
      swal({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          let uid = localStorage.getItem('uid')
          let role = localStorage.getItem('role')
          let data = {
            teacher_id: `${params.row.user_id}`,
            user_id: uid,
            role,
          }
          Axios.post('teacher/delete_teacher', data)
            .then((res) => {
              if (res.data.message === 'not allowed to delete') {
                swal('Your Not Allowed to Delete!', {
                  icon: 'warning',
                })
              } else {
                swal('Teacher has been deleted!', {
                  icon: 'success',
                })
                params.api.setRows(res?.data?.data)
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
    }
  }
  const onView = (params) => {
    if (history.location.pathname.includes('student')) {
      history.push(`/student/${params.row.user_id}`)
    } else {
      history.push(`/teacher/${params.row.user_id}`)
    }
  }
  const onUnassociation = (params) => {
    // console.log(params);
    // return;
    if (history.location.pathname.includes('student')) {
      if (params.row.course_id == '---') {
        toast('Student is not associated to any course', {
          position: 'top-center',
          autoClose: 5000,
          theme: 'colored',
          type: 'warning',
        })
      } else {
        swal({
          title: 'Are you sure?',
          text: 'Once unassociated, you will not be able to associate again!',
          icon: 'warning',
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            let uid = window.localStorage.getItem('uid')
            let myData = {
              student_id: params.row.user_id,
              course_id: params.row.course_id,
              user_id: uid,
              role: 'admin',
            }
            // console.log(params.row.course_id, "params.row.course_id");
            Axios.post('student/remove_student_association', myData)
              .then((res) => {
                if (res.data.message === 'success') {
                  swal('Unassociated!', {
                    icon: 'success',
                  })
                  res?.data?.result?.map((item, index) => {
                    item.uid = item.user_id
                    item.id = index + 1
                    return item
                  })
                  params.api.setRows(res.data.result)
                }
              })
              .catch((err) => {
                console.log(err)
              })
          }
        })
      }
    }
  }
  return (
    <strong>
      <button
        className="btn btn-success"
        onClick={() => {
          onView(params)
        }}
      >
        <i className="fas fa-user"></i> View Profile
      </button>{' '}
      <button
        className="btn btn-danger"
        onClick={() => {
          onDelete(params)
        }}
      >
        <i className="fas fa-times"></i> Delete
      </button>{' '}
      {history.location.pathname.includes('student') && (
        <button
          className="btn btn-info"
          onClick={() => {
            onUnassociation(params)
          }}
        >
          <i className="fas fa-times"></i> Unassociate Student
        </button>
      )}
    </strong>
  )
}
