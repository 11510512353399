import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { currency } from "../../utilities/GlobalCurrency";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DatePicker from "@mui/lab/DatePicker";
// import img from "../../assests/img/figure/user4.jpg";
import { toast } from "react-toastify";

const Prices = ({
  prices,
  seleted,
  setSeleted,
  onPriceAdd,
  setPrices,
  onPriceDelete,
  onPriceHandleChange,
}) => {
  React.useEffect(() => {
    if (prices.length === 0) {
      setPrices([
        {
          country_name: "",
          currency_code: "",
          country_code: "",
          price_value: "",
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePriceChange = (e, index, d) => {
    if (d !== "") {
      onPriceHandleChange(e, index, d);
      let m = currency.filter((c) => c.Country === d);
      let v = [...seleted];
      v.push(m[0]);
      // console.log(m, "pilottoooooooooooooooooooooo");
      setPrices((prev) => {
        let p = [...prev];
        p[index].currency_code = m[0].Code;
        p[index].country_code = m[0].CountryCode;
        return p;
      });
    } else {
      setPrices((prev) => {
        let p = [...prev];
        p[index].currency_code = "";
        p[index].country_code = "";
        return p;
      });
    }
  };
  return (
    <div>
      {prices?.map((price, index) => {
        return (
          <>
            <div className="row">
              <div className="col-md-4 col-xl-4 col-lg-4 col-12 form-group r-m">
                <FormControl fullWidth className="regions-field-ali">
                  <InputLabel id="regions-select-helper-label">
                    Regions
                  </InputLabel>
                  <Select
                    labelId="regions-select-helper-label"
                    id="regions-select-helper"
                    label="Regions"
                    name="country_name"
                    value={price?.country_name}
                    onChange={(e) =>
                      handlePriceChange(e, index, e.target.value)
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {currency.map((c) => {
                      return (
                        <MenuItem value={c?.Country}>{c?.Country}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-4 col-xl-4 col-lg-4 col-12 form-group add-in">
                <TextField
                  id="outlined-basic"
                  label={price.currency_code ? price.currency_code : "Price"}
                  variant="outlined"
                  type="text"
                  name="price_value"
                  value={`${price.price_value}`}
                  onChange={(e) => {
                    const up = e.target.value.replace(/[^0-9]/g, "");
                    onPriceHandleChange(e, index, up);
                  }}
                />
                {prices?.length !== 1 && index !== 0 ? (
                  <button
                    type="submit"
                    className="btn btn-danger ml-2"
                    onClick={() => {
                      onPriceDelete(index);
                    }}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                ) : null}
              </div>
            </div>
          </>
        );
      })}
      <div className="row">
        <div className="col-md-12 col-xl-12 col-lg-12 col-12 form-group r-m">
          <button
            onClick={(e) => {
              e.preventDefault();
              if (prices && prices?.[prices.length - 1]?.currency_code) {
                onPriceAdd();
              } else {
                toast("Please fill the details to continue", {
                  position: "top-",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  type: "info",
                  theme: "colored",
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }}
            type="submit"
            className="btn-fill-lg bg-blue-dark btn-hover-yellow radius-30"
          >
            + Add More Regions
          </button>
        </div>
      </div>
    </div>
  );
};

export default Prices;
