export const AUTH_TYPES = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  LOGIN_CHECK: "LOGIN_CHECK",
  SET_ROLE: "SET_ROLE",
  SET_ROL: "SET_ROL",
  SET_USER: "SET_USER",
  SELECTED_STUDENTS: "SELECTED_STUDENTS",
  SELECTED_COURSE: "SELECTED_COURSE",
  SELECTED_CLASS: "SELECTED_CLASS",
};
