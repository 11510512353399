import React from "react";
import Axios from "../../utilities/axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useHistory } from "react-router";
import swal from "sweetalert";
import Filtersearch from "../commonComponents/Filtersearch";

const Teacherslist = () => {
  const [rows, setRows] = React.useState([]);
  const [dummy, setDummy] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    const uid = localStorage.getItem("uid");
    Axios.get(`course_admin/getallteachers_for_courseadmin/${uid}`)
      .then((res) => {
        let s = res && res?.data?.data;
        setRows(s.length > 0 ? s : []);
        setDummy(s.length > 0 ? s : []);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 220,
      hide: true,
    },
    {
      field: "user_id",
      headerName: "Teacher ID",
      width: 220,
    },
    {
      field: "fullname",
      headerName: "Teacher Name",
      width: 250,
    },
    {
      field: "course_name",
      headerName: "Course Name",
      width: 250,
    },
    {
      field: "work_status",
      headerName: "Work Status",
      width: 190,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 180,
      sortable: false,
      renderCell: RenderDetailsButton,
      disableClickEventBubbling: true,
    },
  ];

  return (
    <>
      <div className="card height-auto">
        <div className="card-body">
          <Filtersearch dummy={dummy} setRows={setRows} />
          {rows.length > 0 || rows.length == 0 ? (
            <div
              className="table-responsive"
              style={{ height: 650, width: "100%" }}
            >
              <DataGrid
                components={{
                  Toolbar: GridToolbar,
                }}
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                loading={loading}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Teacherslist;

const RenderDetailsButton = (params) => {
  let history = useHistory();
  const onDelete = (params) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let uid = localStorage.getItem("uid");
        let role = localStorage.getItem("role");
        let data = {
          teacher_id: `${params.row.user_id}`,
          user_id: uid,
          role,
        };
        Axios.post("teacher/delete_teacher", data)
          .then((res) => {
            if (res.data.message == "not allowed to delete") {
              swal("Your Not Allowed to Delete!", {
                icon: "warning",
              });
            } else {
              swal("Teacher has been deleted!", {
                icon: "success",
              });
              params.api.setRows(res?.data?.data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        swal("Your Operation is reverted!");
      }
    });
  };
  const onView = (params) => {
    history.push(`/Single Teacher/${params.row.user_id}`);
  };
  return (
    <strong>
      <button
        className="btn btn-success"
        onClick={() => {
          onView(params);
        }}
      >
        <i className="fas fa-user"></i> View Profile
      </button>{" "}
      <button
        className="btn btn-danger"
        onClick={() => {
          onDelete(params);
        }}
      >
        <i className="fas fa-times"></i> Delete
      </button>
    </strong>
  );
};
