import React from "react";
import Mycard from "./commonComponents/Mycard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useParams } from "react-router";
import Axios from "../utilities/axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import parse from "html-react-parser";
import Filtersearch from "./commonComponents/Filtersearch";

const Person = ({ who }) => {
  const [result, setResult] = React.useState([{}]);
  const [rows, setRows] = React.useState([]);
  let { id } = useParams();
  React.useEffect(() => {
    if (who === "courseadmin") {
      Axios.get(`course_admin/get_single_courseadmin/${id}`)
        .then((res) => {
          let d = res?.data?.data;
          setResult(d);
          setRows(d?.[2]);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Axios.get(`/teacher/get_single_teacher/${id}`)
        .then((res) => {
          let r = res && res?.data && res?.data?.data;
          setResult(r?.length > 0 ? res?.data?.data : []);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //eslint-disable-next-line
  }, []);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const teacher = [
    {
      iClass: "flaticon-open-book text-orange-peel",
      name: `${result && result?.[1]?.length > 1 ? "Courses" : "Course"}`,
      number: `${
        result && result?.[1]?.length > 1
          ? result?.[1]?.length
          : result?.[1]?.[0]?.course_name === undefined
          ? "Not Assigned"
          : result?.[1]?.[0]?.course_name
      }`,
    },
    {
      iClass: "flaticon-multiple-users-silhouette text-green",
      name: "Associated Duration",
      number: `${result?.[0]?.[0]?.associated_from}`,
    },
    {
      iClass: "flaticon-classmates text-red",
      name: "Students",
      number: `${
        result?.[1]?.[0]?.total_students === undefined
          ? "0"
          : result?.[1]?.[0]?.total_students
      }`,
    },
  ];
  // console.log(result, "result");
  const courseAdmin = [
    {
      iClass: "flaticon-open-book text-orange",
      name: "Total Courses",
      number: result?.[1]?.length,
    },
    {
      iClass: "flaticon-couple text-green",
      name: "Teachers",
      number: result?.[0]?.[0]?.total_teachers,
    },
    {
      iClass: "flaticon-classmates text-magenta",
      name: "Sudents",
      number: result?.[0]?.[0]?.total_students,
    },
  ];
  const columns = [
    { field: "id", headerName: "ID", width: 150, type: "string" },
    { field: "course_name", headerName: "Course", width: 150 },
    {
      field: "fullname",
      headerName: "Teachers",
      width: 150,
    },
    {
      field: "students",
      headerName: "Students",
      type: "number",
      width: 140,
    },
    {
      field: "course_duration",
      headerName: "Duration",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
  ];
  return (
    <>
      {result ? (
        <>
          <div className="card height-auto">
            <div className="card-body">
              <h4 className="main-h">
                {result?.[0]?.[0]?.fullname?.toUpperCase()}
              </h4>

              <section className="mg-b-20">
                <div className="row">
                  <div className="col-md-8 w-topics w-t-view">
                    <Carousel responsive={responsive} infiniteLoop={true}>
                      {who === "courseadmin"
                        ? courseAdmin.map((item, index) => (
                            <div key={index} className="carou-slider">
                              <Mycard
                                iClass={item.iClass}
                                name={item.name}
                                number={item.number}
                                key={index}
                              />
                            </div>
                          ))
                        : teacher &&
                          teacher.map((item, index) => (
                            <div key={index} className="carou-slider">
                              <Mycard
                                iClass={item.iClass}
                                name={item.name}
                                number={item.number}
                                key={index}
                              />
                            </div>
                          ))}
                    </Carousel>
                    <div className="card-body table-responsive">
                      <div style={{ height: 400, width: "100%" }}>
                        {rows ? (
                          <DataGrid
                            components={{
                              Toolbar: GridToolbar,
                            }}
                            rows={rows && rows}
                            columns={columns}
                            autoPageSize={true}
                            pageSize={6}
                            rowsPerPageOptions={[6]}
                          />
                        ) : (
                          <div id="preloader" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 w-topics w-t-view">
                    <div className="c-profile">
                      <img alt="not loaded" src={result?.[0]?.[0]?.image}></img>
                      <div className="c-content">
                        {/* <div>About</div> */}
                        <div className="c-values">
                          {result &&
                            result?.[0]?.[0]?.description &&
                            (result?.[0]?.[0]?.description === ""
                              ? "No Bio"
                              : parse(result?.[0]?.[0]?.description))}
                        </div>
                      </div>
                      <div className="c-content">
                        <div>Gender</div>
                        <div className="c-values">
                          {result?.[0]?.[0]?.gender}
                        </div>
                      </div>
                      <div className="c-content">
                        <div>Date Of Birth</div>
                        <div className="c-values">{result?.[0]?.[0]?.dob}</div>
                      </div>
                      {/* <div className="c-content">
                    <div>Teachers</div>
                    <div className="c-values">
                      <span>{data?.[2]?.[0]?.fullname || "John Deo"}</span>
                    </div>
                  </div> */}

                      <div className="c-content">
                        <div>E-Mail</div>
                        <div className="c-values">
                          {result?.[0]?.[0]?.email_id}
                        </div>
                      </div>
                      <div className="c-content">
                        <div>Phone</div>
                        <div className="c-values">
                          {result?.[0]?.[0]?.phone_number}
                        </div>
                      </div>
                      <div className="c-content">
                        <div>Address</div>
                        <div className="c-values">
                          {result?.[0]?.[0]?.address}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </>
      ) : (
        <div id="preloader"></div>
      )}
    </>
  );
};
export default Person;
