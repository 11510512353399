import React from "react";
// import { Link } from "react-router-dom";

const Glipmsecard = ({ iClass, name, number, src }) => {
  return (
    <section className="g-card">
      <div className="dashboard-summery-one mg-b-20 g-sec">
        <div className="row align-items-center">
          <div className="col-4">
            <div className="item-icon bg-light-blue g-img ">
              {!src ? (
                <i className={iClass} />
              ) : (
                <img
                  src={src}
                  className="image-fluid"
                  width="85"
                  height="85"
                  alt=""
                />
              )}
            </div>
          </div>
          <div className="col-8">
            <div className="item-content">
              <div className="item-number">{name}</div>
              <div className="item-title">
                {number?.length > 42
                  ? number?.substring(0, 42) + "..."
                  : number}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Glipmsecard;
