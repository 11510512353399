import React, { useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../../assests/img/logo33.png";
import { authContext } from "../../context/AuthContext";
import { AUTH_TYPES } from "../../utilities/authTypes";
import { useHistory } from "react-router-dom";
import Axios from "../../utilities/axios";
import Switch from "@mui/material/Switch";
import { toast } from "react-toastify";
// import swal from "sweetalert";

const Navbar = ({ isChanged }) => {
  const { dispatch, authState } = useContext(authContext);
  let history = useHistory();
  const onLogOut = () => {
    localStorage.clear();
    dispatch({ type: AUTH_TYPES.LOGOUT });
  };
  const handleLogoClick = () => {
    let role = localStorage.getItem("role");
    if (role === "admin") {
      history.push("/Account Admin Dashboard");
    } else if (role === "courseadmin") {
      history.push("/Course Admin Dashboard");
    } else if (role === "teacher") {
      history.push("/Teacher Dashboard");
    } else {
      history.push("/login");
    }
  };
  const [checked, setChecked] = React.useState(false);
  const [asRole, setAsRole] = React.useState(null);
  const handleChange = (event) => {
    if (!checked) {
      let role = localStorage.getItem("role");
      role = role === "courseadmin" ? "teacher" : "courseadmin";
      if (window.confirm("Do you want to take another role ?")) {
        setChecked(event.target.checked);
        let uid = localStorage.getItem("uid");
        let d = {
          user_id: uid,
          role,
        };
        Axios.post("user_details/switch_role", d)
          .then((res) => {
            if (res?.data?.status === 200) {
              toast(res?.data?.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                theme: "dark",
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((err) => {
            console.log("err", err);
            toast("Something went wrong", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "dark",
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    } else {
      if (window.confirm("Are you Sure ?")) {
        let uid = localStorage.getItem("uid");
        let d = {
          user_id: uid,
          role: asRole,
        };
        Axios.post("user_details/switch_role", d)
          .then((res) => {
            if (res?.data?.status === 200) {
              if (res?.data?.message != "success") {
                toast(res?.data?.message, {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  type: "warning",
                  theme: "colored",
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setChecked(event.target.checked);
              } else {
                toast(res?.data?.message, {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  theme: "dark",
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setChecked(!event.target.checked);
              }
            }
          })
          .catch((err) => {
            console.log("err", err);
            toast("Something went wrong", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "dark",
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    }
  };

  // const handleChange = (event) => {
  //   // if (!checked) {
  //   //   let role = localStorage.getItem("role");
  //   //   role = role === "courseadmin" ? "teacher" : "courseadmin";
  //   //   swal({
  //   //     title: "Are you sure?",
  //   //     text: "Once deleted, you will not be able to recover!",
  //   //     icon: "warning",
  //   //     buttons: true,
  //   //     dangerMode: true,
  //   //   }).then((willDelete) => {
  //   //     if (willDelete) {
  //   //       setChecked(event.target.checked);
  //   //       let uid = localStorage.getItem("uid");
  //   //       let d = {
  //   //         user_id: uid,
  //   //         role,
  //   //       };
  //   //       Axios.post("user_details/switch_role", d)
  //   //         .then((res) => {
  //   //           if (res?.data?.status === 200) {
  //   //             swal(res?.data?.message, {
  //   //               icon: "success",
  //   //             });
  //   //             // toast(res?.data?.message, {
  //   //             //   position: "top-center",
  //   //             //   autoClose: 2000,
  //   //             //   hideProgressBar: false,
  //   //             //   closeOnClick: true,
  //   //             //   theme: "dark",
  //   //             //   pauseOnHover: true,
  //   //             //   draggable: true,
  //   //             //   progress: undefined,
  //   //             // });
  //   //           }
  //   //         })
  //   //         .catch((err) => {
  //   //           console.log("err", err);
  //   //           toast("Something went wrong", {
  //   //             position: "top-center",
  //   //             autoClose: 2000,
  //   //             hideProgressBar: false,
  //   //             closeOnClick: true,
  //   //             theme: "dark",
  //   //             pauseOnHover: true,
  //   //             draggable: true,
  //   //             progress: undefined,
  //   //           });
  //   //         });
  //   //     }
  //   //   });
  //   //   // if (window.confirm("Do you want to take another role ?")) {

  //   //   // }
  //   // } else {
  //   //   if (window.confirm("Are you Sure ?")) {
  //   //     let uid = localStorage.getItem("uid");
  //   //     let d = {
  //   //       user_id: uid,
  //   //       role: asRole,
  //   //     };
  //   //     Axios.post("user_details/switch_role", d)
  //   //       .then((res) => {
  //   //         if (res?.data?.status === 200) {
  //   //           if (res?.data?.message != "success") {
  //   //             toast(res?.data?.message, {
  //   //               position: "top-center",
  //   //               autoClose: 2000,
  //   //               hideProgressBar: false,
  //   //               closeOnClick: true,
  //   //               type: "warning",
  //   //               theme: "colored",
  //   //               pauseOnHover: true,
  //   //               draggable: true,
  //   //               progress: undefined,
  //   //             });
  //   //             setChecked(event.target.checked);
  //   //           } else {
  //   //             toast(res?.data?.message, {
  //   //               position: "top-center",
  //   //               autoClose: 2000,
  //   //               hideProgressBar: false,
  //   //               closeOnClick: true,
  //   //               theme: "dark",
  //   //               pauseOnHover: true,
  //   //               draggable: true,
  //   //               progress: undefined,
  //   //             });
  //   //             setChecked(!event.target.checked);
  //   //           }
  //   //         }
  //   //       })
  //   //       .catch((err) => {
  //   //         console.log("err", err);
  //   //         toast("Something went wrong", {
  //   //           position: "top-center",
  //   //           autoClose: 2000,
  //   //           hideProgressBar: false,
  //   //           closeOnClick: true,
  //   //           theme: "dark",
  //   //           pauseOnHover: true,
  //   //           draggable: true,
  //   //           progress: undefined,
  //   //         });
  //   //       });
  //   //   }
  //   // }
  // };

  React.useEffect(() => {
    let uid = localStorage.getItem("uid");
    Axios.get(`user_details/get_user_profile/${uid}`)
      .then((res) => {
        dispatch({
          type: AUTH_TYPES.SET_USER,
          payload: res && res?.data?.data[0],
        });
        let as = res?.data?.data[0]?.as_role;
        setAsRole(as);
        if (!res?.data?.data[0]?.as_role) {
          setChecked(false);
        } else {
          setChecked(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //eslint-disable-next-line
  }, [isChanged]);
  React.useEffect(() => {
    let uid = localStorage.getItem("uid");
    Axios.get(`user_details/get_user_profile/${uid}`)
      .then((res) => {
        let as = res?.data?.data[0]?.as_role;
        setAsRole(as);
        if (!as) {
          setChecked(false);
        } else {
          setChecked(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="navbar navbar-expand-md header-menu-one bg-light">
        <div className="nav-bar-header-one">
          <div
            className="header-logo pointer"
            onClick={() => handleLogoClick()}
          >
            <img src={logo} alt="logo" width="189px" className="pointer" />
          </div>
          <div className="toggle-button sidebar-toggle">
            <button type="button" className="item-link">
              <span className="btn-icon-wrap">
                <span />
                <span />
                <span />
              </span>
            </button>
          </div>
        </div>
        <div className="d-md-none mobile-nav-bar">
          <button
            className="navbar-toggler pulse-animation"
            type="button"
            data-toggle="collapse"
            data-target="#mobile-navbar"
            aria-expanded="false"
          >
            <i className="far fa-arrow-alt-circle-down" />
          </button>
          <button
            type="button"
            className="navbar-toggler sidebar-toggle-mobile"
          >
            <i className="fas fa-bars" />
          </button>
        </div>
        <div
          className="header-main-menu collapse navbar-collapse custom-header-menu"
          id="mobile-navbar"
        >
          <ul className="navbar-nav">
            <li className="navbar-item header-search-bar">
              {localStorage.getItem("mainrole") ==
                localStorage.getItem("role") &&
                localStorage.getItem("role") != "admin" && (
                  <div className="input-group stylish-input-group become-a">
                    <h6>
                      <span>You want to become a</span>
                      {localStorage.getItem("role") === "courseadmin"
                        ? " Teacher "
                        : " CourseAdmin "}{" "}
                      <span className="pointing-icn">
                        <i className="far fa-hand-point-right"></i>
                      </span>
                      <span className="s-n">NO</span>
                    </h6>
                    <Switch
                      checked={checked}
                      defaultChecked
                      onChange={(e) => handleChange(e)}
                      color="warning"
                      inputProps={{ "aria-label": "controlled" }}
                      className="become-swit"
                    />
                    <h6>
                      <span className="s-n">YES</span>
                    </h6>
                  </div>
                )}
            </li>
          </ul>
          <ul className="navbar-nav">
            <li className="navbar-item dropdown header-admin">
              <div
                className="navbar-nav-link dropdown-toggle pointer"
                href="/#"
                role="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="admin-title">
                  <h5 className="item-title">
                    {authState.currentUser?.fullname}
                  </h5>
                  <span>
                    {" "}
                    {localStorage.getItem("role") === "admin"
                      ? "Account Admin"
                      : localStorage.getItem("role") === "courseadmin"
                      ? "Course Admin"
                      : "Teacher"}
                  </span>
                </div>
                <div className="admin-img">
                  <Link to="/profile">
                    <img
                      src={authState.currentUser?.image}
                      alt="Not loaded"
                      style={{ height: "4rem" }}
                    />
                  </Link>
                </div>
              </div>
              <div className="dropdown-menu dropdown-menu-right">
                <div className="item-header">
                  <h6 className="item-title">
                    {authState.currentUser?.fullname}
                  </h6>
                </div>
                <div className="item-content">
                  <ul className="settings-list">
                    <li>
                      <Link to="/profile">
                        <i className="flaticon-user" />
                        My Account
                      </Link>
                    </li>

                    <li>
                      <Link to="/changepassword">
                        <i className="flaticon-gear-loading" />
                        Change password
                      </Link>
                    </li>

                    <li onClick={() => onLogOut()}>
                      <Link to="/login">
                        <i className="flaticon-turn-off" />
                        Log Out
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
