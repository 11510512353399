import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { currency } from "../utilities/GlobalCurrency";
import { formatDate } from "../utilities/convert";
import Axios from "../utilities/axios";
import { uploadFile } from "react-s3";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import RichTextEditor from "react-rte";

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET,
  region: process.env.REACT_APP_REGION,
  dirName: "folder",
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};
const Editprofile = ({ setIsChanged, isChanged }) => {
  const [value, setValues] = React.useState(RichTextEditor.createEmptyValue());
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    description: "",
    image: "",
    gender: "",
    dob: null,
    address: "",
    pincode: "",
    city: "",
    state: "",
    region: "",
  });
  const [userv, setUserv] = useState({
    first_name: false,
    last_name: false,
    phone_number: false,
    description: false,
    image: false,
    gender: false,
    dob: false,
    address: false,
    pincode: false,
    city: false,
    state: false,
    region: false,
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const handleUploadImage = async (file) => {
    //validate file
    // console.log(user, "user");
    if (user.first_name === "") {
      setUserv({ ...userv, first_name: true });
      return;
    } else if (user.last_name === "") {
      setUserv({ ...userv, last_name: true });
      return;
    } else if (user.gender === "") {
      setUserv({ ...userv, gender: true });
      return;
    } else if (user.phone_number === "") {
      setUserv({ ...userv, phone_number: true });
      return;
    } else if (user.description === "") {
      setUserv({ ...userv, description: true });
      return;
    } else if (user.city === "") {
      setUserv({ ...userv, city: true });
      return;
    } else if (user.state === "") {
      setUserv({ ...userv, state: true });
      return;
    } else if (user.region === "") {
      setUserv({ ...userv, region: true });
      return;
    } else if (user.dob === "1970-01-01") {
      setUserv({ ...userv, dob: true });
      return;
    } else if (user.address === "") {
      setUserv({ ...userv, address: true });
      return;
    } else if (user.pincode === "") {
      setUserv({ ...userv, pincode: true });
      return;
    } else {
      await uploadFile(file, config)
        .then((data) => {
          if (data?.result?.ok) {
            handleUpdate(data?.location);
          }
        })
        .catch((err) => handleUpdate(user?.image));
    }
  };
  let history = useHistory();
  const handleUpdate = (image) => {
    let data = user;
    data.image = image;
    Axios.post("user_details/update_profile", data)
      .then((res) => {
        if (res?.data?.status === 200) {
          toast("Profile has been Updated..", {
            type: "success",
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsChanged(!isChanged);
          history.push("/profile");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let uid = localStorage.getItem("uid");
    Axios.get(`user_details/get_user_details/${uid}`)
      .then((res) => {
        let user = res?.data?.data[0];
        setUser(user);
        setValues(
          RichTextEditor.createValueFromString(user.description, "html")
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const uploadImage = async (e) => {
    setSelectedFile(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setUser({ ...user, image: reader.result });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleChange = (e) => {
    setValues(e);
    setUser({ ...user, description: value.toString("html") });
    // setDetails({ ...details, course_description: e.toString("html") });
    // setDummy(true);
    // setDummy(true);
    // setDummy(true);
  };

  return (
    <>
      <div className="row">
        {/* Admit Form Area Start Here */}
        <div className="card height-auto">
          <div className="card-body">
            <div className="heading-layout1">
              <div className="item-title">{/* <h4>Edit profile</h4> */}</div>
            </div>
            <form className="new-added-form">
              <div className="row">
                <div className="col-lg-12 col-12 form-group mg-t-30 u-l-img">
                  <img
                    src={user.image}
                    alt="user"
                    onClick={() => document.getElementById("upload").click()}
                  />
                  <i
                    className="fas fa-cloud-upload-alt pointer"
                    onClick={() => document.getElementById("upload").click()}
                  ></i>
                  {/* <label className="text-dark-medium">
                    Upload Student Photo (150px * 150px)
                  </label> */}
                  <input
                    type="file"
                    id="upload"
                    accept="image/*"
                    className="form-control-file"
                    onChange={(e) => uploadImage(e)}
                    style={{ display: "none" }}
                  />
                </div>
                <div className="col-xl-4 col-lg-6 col-12 form-group">
                  <TextField
                    id="outlined-basic"
                    label="Enter First Name *"
                    variant="outlined"
                    value={user.first_name} //
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setUserv({ ...userv, first_name: false });
                      } else if (e.target.value.length === 0) {
                        setUserv({ ...userv, first_name: true });
                      }
                      setUser({ ...user, first_name: e.target.value });
                    }}
                  />
                  {userv.first_name ? (
                    <div className="error">
                      <p>Please Enter First Name</p>
                    </div>
                  ) : null}
                </div>
                <div className="col-xl-4 col-lg-6 col-12 form-group">
                  <TextField
                    id="outlined-basic"
                    label="Enter Last Name *"
                    variant="outlined"
                    value={user.last_name}
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setUserv({ ...userv, last_name: false });
                      } else if (e.target.value.length === 0) {
                        setUserv({ ...userv, last_name: true });
                      }
                      setUser({ ...user, last_name: e.target.value });
                    }}
                    //   // setUser({ ...user, last_name: e.target.value })
                    // }
                  />
                  {userv.last_name ? (
                    <div className="error">
                      <p>Please Enter Last Name</p>
                    </div>
                  ) : null}
                </div>
                <div className="col-xl-4 col-lg-6 col-12 form-group">
                  <FormControl fullWidth>
                    <InputLabel id="course-admin-select-helper-label">
                      Gender *
                    </InputLabel>
                    <Select
                      labelId="course-admin-select-helper-label"
                      id="course-admin-select-helper"
                      label="Gender *"
                      value={user.gender}
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          setUserv({ ...userv, gender: false });
                        }
                        setUser({
                          ...user,
                          gender: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {["male", "female", "others"].map((name) => (
                        <MenuItem value={name}>{name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {userv.gender ? (
                    <div className="error">
                      <p>Gender Required</p>
                    </div>
                  ) : null}
                </div>
                <div className="col-xl-4 col-lg-6 col-12 form-group">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Date of Birth *"
                      value={user.dob}
                      inputFormat="dd-MMM-yyyy"
                      onChange={(e) => setUser({ ...user, dob: formatDate(e) })}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  {userv.dob ? (
                    <div className="error">
                      <p>Please Enter Date of Birth</p>
                    </div>
                  ) : null}
                </div>
                <div className="col-xl-4 col-lg-6 col-12 form-group">
                  <TextField
                    id="outlined-basic"
                    label="Enter Phone Number *"
                    variant="outlined"
                    type="number"
                    value={user.phone_number}
                    onChange={
                      (e) => {
                        if (e.target.value.length > 0) {
                          setUserv({ ...userv, phone_number: false });
                        } else if (e.target.value.length === 0) {
                          setUserv({ ...userv, phone_number: true });
                        }
                        setUser({ ...user, phone_number: e.target.value });
                      }
                      // setUser({ ...user, phone_number: e.target.value })
                    }
                  />
                  {userv.phone_number ? (
                    <div className="error">
                      <p>Please Enter Phone Number</p>
                    </div>
                  ) : null}
                </div>
                <div className="col-xl-4 col-lg-6 col-12 form-group">
                  <TextField
                    id="outlined-basic"
                    label="Enter Address Line 1 *"
                    variant="outlined"
                    value={user.address}
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setUserv({ ...userv, address: false });
                      } else if (e.target.value.length === 0) {
                        setUserv({ ...userv, address: true });
                      }
                      setUser({ ...user, address: e.target.value });
                    }}
                    // setUser({ ...user, address: e.target.value })
                  />
                  {userv.address ? (
                    <div className="error">
                      <p>Please Enter Address Line 1</p>
                    </div>
                  ) : null}
                </div>
                <div className="col-xl-4 col-lg-6 col-12 form-group">
                  <TextField
                    id="outlined-basic"
                    label="Enter City name *"
                    variant="outlined"
                    value={user.city}
                    onChange={
                      (e) => {
                        if (e.target.value.length > 0) {
                          setUserv({ ...userv, city: false });
                        } else if (e.target.value.length === 0) {
                          setUserv({ ...userv, city: true });
                        }
                        setUser({ ...user, city: e.target.value });
                      }
                      // setUser({ ...user, city: e.target.value })
                    }
                  />
                  {userv.city ? (
                    <div className="error">
                      <p>Please Enter City name</p>
                    </div>
                  ) : null}
                </div>
                <div className="col-xl-4 col-lg-6 col-12 form-group">
                  <TextField
                    id="outlined-basic"
                    label="Enter State *"
                    variant="outlined"
                    value={user.state}
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setUserv({ ...userv, state: false });
                      } else if (e.target.value.length === 0) {
                        setUserv({ ...userv, state: true });
                      }
                      setUser({ ...user, state: e.target.value });
                    }}
                  />
                  {userv.state ? (
                    <div className="error">
                      <p>Please Enter State</p>
                    </div>
                  ) : null}
                </div>
                <div className="col-xl-4 col-lg-6 col-12 form-group">
                  <TextField
                    id="outlined-basic"
                    label="Enter pincodeCode *"
                    variant="outlined"
                    value={user.pincode}
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setUserv({ ...userv, pincode: false });
                      } else if (e.target.value.length === 0) {
                        setUserv({ ...userv, pincode: true });
                      }
                      setUser({ ...user, pincode: e.target.value });
                    }}
                  />
                  {userv.pincode ? (
                    <div className="error">
                      <p>Please Enter pincodeCode</p>
                    </div>
                  ) : null}
                </div>
                <div className="col-xl-4 col-lg-6 col-12 form-group">
                  <FormControl fullWidth>
                    <InputLabel id="course-admin-select-helper-label">
                      Region *
                    </InputLabel>
                    <Select
                      labelId="course-admin-select-helper-label"
                      id="course-admin-select-helper"
                      value={user.region}
                      label="TimeZone *"
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          setUserv({ ...userv, region: false });
                        }
                        setUser({ ...user, region: e.target.value });
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {currency.map((name) => (
                        <MenuItem value={name.Country}>
                          {name.Country + "(" + name.CountryCode + ")"}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {userv.region ? (
                    <div className="error">
                      <p>Please Enter Region</p>
                    </div>
                  ) : null}
                </div>
                <div className="col-lg-12 col-12">
                  <RichTextEditor
                    placeholder="Write Something About Yourself"
                    value={value}
                    onChange={(e) => handleChange(e)}
                  />
                  {userv.description ? (
                    <div className="error">
                      <p>Please Enter Description</p>
                    </div>
                  ) : null}
                </div>
                <div className="col-12 form-group mg-t-8">
                  <button
                    type="button"
                    onClick={() => handleUploadImage(selectedFile)}
                    className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Editprofile;
