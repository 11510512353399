import React from "react";
import Summary from "./Summary";

const Home = () => {
  return (
    <>
      <Summary />
    </>
  );
};

export default Home;
