import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "../utilities/axios";
import parse from "html-react-parser";

const Profile = () => {
  const [user, setUser] = useState({});
  useEffect(() => {
    let uid = localStorage.getItem("uid");
    Axios.get(`user_details/get_user_details/${uid}`)
      .then((res) => {
        let user = res?.data?.data[0];
        setUser(user);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="row">
        {/* Student Info Area Start Here */}
        <div className="col-md-12">
          <div className="card dashboard-card-ten">
            <div className="card-body">
              {/* <div className="heading-layout1">
                <div className="item-title">
                  <h3>My Profile</h3>
                </div>
              </div> */}
              <div className="student-info">
                <div className="media media-none--xs">
                  <div className="item-img">
                    <img
                      src={user?.image}
                      className="media-img-auto image-fluid"
                      alt="student"
                    />
                  </div>
                  <div className="media-body">
                    <h3 className="item-title">
                      {/* {console.log(user)} */}
                      {user?.fullname && user?.fullname?.toUpperCase()}
                    </h3>
                    {console.warn(user)}
                    <p>
                      {user &&
                        user?.description &&
                        (user?.description === ""
                          ? "No Bio"
                          : parse(user.description))}
                    </p>
                  </div>
                </div>
                <div className="table-responsive info-table">
                  <table className="table text-nowrap">
                    <tbody>
                      <tr>
                        <td>Gender</td>
                        <td className="font-medium text-dark-medium">
                          : {user?.gender}
                        </td>
                      </tr>
                      <tr>
                        <td>Date Of Birth</td>
                        <td className="font-medium text-dark-medium">
                          : {user?.dob}
                        </td>
                      </tr>
                      <tr>
                        <td>E-Mail</td>
                        <td className="font-medium text-dark-medium">
                          : {user?.email_id}
                        </td>
                      </tr>

                      <tr>
                        <td>Address</td>
                        <td className="font-medium text-dark-medium">
                          :{" "}
                          {user?.address
                            ? user?.address +
                              ", " +
                              user?.city +
                              ", " +
                              user?.state +
                              ", " +
                              user?.pincode
                            : null}
                        </td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td className="font-medium text-dark-medium">
                          : {user?.phone_number}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-1-xxxl col-xl-2 col-lg-3 col-12 form-group">
                    <Link to="/editprofile">
                      <button
                        type="submit"
                        className="fw-btn-fill btn-gradient-yellow"
                      >
                        Edit Profile
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
