import React, { useContext } from "react";
import { authContext } from "../context/AuthContext";
import { useHistory, useLocation } from "react-router";
import CryptoJS from "crypto-js";
import logo from "../../src/assests/img/rushividyahyasam-small-logo.png";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Axios from "../utilities/axios";
import { toast } from "react-toastify";

const Signup = () => {
  // const [real, setReal] = React.useState(null);
  const [errorfname, setErrorfname] = React.useState(false);
  const [errorlname, setErrorlname] = React.useState(false);
  const [erroremail, setErroremail] = React.useState(false);
  const [errorpassword, setErrorpassword] = React.useState(false);
  const [errorcnfp, setErrorcnfp] = React.useState(false);
  const [errorRole, setErrorRole] = React.useState(false);
  let location = useLocation();
  const [obj, setObj] = React.useState({
    email_id: "",
    password: "",
    cpassword: "",
    first_name: "",
    last_name: "",
    role: "",
  });
  const [diasble, setDiasble] = React.useState(false);
  React.useEffect(() => {
    let data =
      location.pathname.split("/")[2] +
      "/" +
      location.pathname.split("/")[3] +
      "/" +
      location.pathname.split("/")[4];
    Axios.post("user_details/getinviting", {
      hash_id: data,
    })
      .then((res) => {
        if (res.data.message === "link expired") {
          // console.log(res.data, "result");
          // setReal("link");
          // toast.success("Link Expired", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          // window.close();
        } else {
          setDiasble(true);
          let obj1 = { ...obj };
          obj1.role = res.data.role;
          obj1.email_id = res.data.email_id;
          setObj(obj1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [values, setValues] = React.useState({
    showPassword: false,
    showCPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowCPassword = () => {
    setValues({
      ...values,
      showCPassword: !values.showCPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownCPassword = (event) => {
    event.preventDefault();
  };

  const { authState } = useContext(authContext);
  React.useEffect(() => {
    const user = authState.isAuthenticated;
    if (user && user !== "undefined") {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
    let error = "no";
    if (obj.first_name === "") {
      setErrorfname(true);
      error = "yes";
    }
    if (obj.last_name === "") {
      setErrorlname(true);
      error = "yes";
    }
    if (obj.email_id === "") {
      setErroremail(true);
      error = "yes";
    }
    if (obj.password === "") {
      setErrorpassword(true);
      error = "yes";
    }
    if (obj.cpassword === "") {
      setErrorcnfp(true);
      error = "yes";
    }
    if (obj.role === "") {
      setErrorRole(true);
      error = "yes";
    }
    if (error === "no") {
      const re = new RegExp(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,20}$"
      );
      const validEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
      const pass1 = re.test(obj.password);
      const emailchk = validEmail.test(obj.email_id);
      if (obj.password !== obj.cpassword) {
        toast("Password and Confirm password should be same", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          type: "warning",
        });
      } else if (!pass1) {
        toast(
          "Password should be 8 characters with at least 1 (Capital Letter, Numeric, Special Character)",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            type: "error",
          }
        );
        return;
      } else if (!emailchk) {
        toast("Email is not Valid,Enter Valid Email", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          type: "warning",
        });
        return;
      } else {
        let data = { ...obj };
        let p = data.password;
        const new_pass = CryptoJS.AES.encrypt(p, "rushi").toString();
        data.password = new_pass;
        data.phone_number = null;
        Axios.post("user_details/registration", data)
          .then((res) => {
            if (res?.data?.message !== "failed") {
              toast("Registered Successfully", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              history.push("/login");
            } else {
              toast(`Cannot Login, ${res.data.error}`, {
                position: "top-center",
                autoClose: 5000,
                theme: "colored",
                type: "error",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((err) => {
            console.log(err, "err");
          });
      }
      console.log("your Entered values", obj);
    }
  };

  return (
    <div className="signup-page-wrap">
      <div className="login-page-content">
        <div className="login-box sign-sec">
          <div className="item-logo auth-logo">
            <img src={logo} alt="logo" />
          </div>
          <form className="login-form">
            <div className="row">
              <div className="col-md-6 mg-b-20">
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  value={obj.first_name}
                  onChange={(e) => {
                    setObj({ ...obj, first_name: e.target.value });
                    setErrorfname(false);
                  }}
                />
                <span className="text-red">
                  {errorfname ? "Firstname is required" : ""}
                </span>
              </div>
              <div className="col-md-6 mg-b-20">
                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  value={obj.last_name}
                  onChange={(e) => {
                    setObj({ ...obj, last_name: e.target.value });
                    setErrorlname(false);
                  }}
                />
                <span className="text-red">
                  {errorlname ? "Lastname is required" : ""}
                </span>
              </div>
              <div className="col-md-12 mg-b-20">
                <TextField
                  id="outlined-basic"
                  label="Email"
                  disabled={diasble}
                  variant="outlined"
                  value={obj.email_id}
                  onChange={(e) => {
                    setErroremail(false);
                    setObj({ ...obj, email_id: e.target.value });
                  }}
                />
                <span className="text-red">
                  {erroremail ? "Email field is required" : ""}
                </span>
              </div>
              <div className="col-md-12 mg-b-20">
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    id="password"
                    type={values.showPassword ? "text" : "password"}
                    value={obj.password}
                    onChange={(e) => {
                      setErrorpassword(false);
                      setObj({ ...obj, password: e.target.value });
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <span className="text-red">
                  {errorpassword ? "Password is required" : ""}
                </span>
              </div>
              <div className="col-md-12 mg-b-20">
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={values.showCPassword ? "text" : "password"}
                    value={obj.cpassword}
                    onChange={(e) => {
                      setErrorcnfp(false);
                      setObj({ ...obj, cpassword: e.target.value });
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCPassword}
                          onMouseDown={handleMouseDownCPassword}
                          edge="end"
                        >
                          {values.showCPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                  />
                </FormControl>
                <span className="text-red">
                  {errorcnfp ? "Confirm password is required" : ""}
                </span>
              </div>
              <div className="col-md-12 mg-b-5">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={obj.role}
                    disabled={diasble}
                    label="Select Role"
                    onChange={(e) => {
                      setErrorRole(false);
                      setObj({ ...obj, role: e.target.value });
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"courseadmin"}>Course Admin</MenuItem>
                    <MenuItem value={"coordinator"}>Co-ordinators</MenuItem>
                    <MenuItem value={"teacher"}>Teacher</MenuItem>
                  </Select>
                </FormControl>
                <span className="text-red">
                  {errorRole ? "Role is required" : ""}
                </span>
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                onClick={(e) => handleSubmit(e)}
                className="login-btn l-btn"
              >
                Create Account
              </button>
            </div>
            <div className="sign-up pre-link">
              Already have an account ? <a href="/login">Sign in!!</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
