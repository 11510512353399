import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
} from "reactstrap";
import TextField from "@mui/material/TextField";
import { uploadFile } from "react-s3";

const GlimpsModel = ({
  glimps,
  className,
  modal,
  toggle,
  handleChangeGlimps,
  addGlimps,
  resetGlimps,
  editInx,
  updateGlimps,
}) => {
  return (
    <Modal isOpen={modal} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>
        {editInx === null ? "Add " : "Edit "}Glimpse
      </ModalHeader>
      <ModalBody>
        <form className="new-added-form">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-8 col-8">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Name *"
                    variant="outlined"
                    name="glimpse_name"
                    value={glimps.glimpse_name}
                    onChange={(e) => handleChangeGlimps(e)}
                  />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
                  <Input
                    type="textarea"
                    placeholder="About Glimpse *"
                    id="exampleText"
                    rows="4"
                    cols="50"
                    name="glimpse_description"
                    value={glimps.glimpse_description}
                    onChange={(e) => handleChangeGlimps(e)}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-4 col-4"
              onClick={() => document.getElementById("iffft").click()}
            >
              <img
                className="img-fluid pointer"
                alt="not yet loaded"
                height="150px"
                width="150px"
                src={glimps.glimpse_image}
              />
              <i
                className="fas fa-cloud-upload-alt"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "116px",
                  left: "126px",
                }}
              ></i>
              <input
                type="file"
                accept="image/*"
                id="iffft"
                name="glimpse_image"
                // style={{ display: "none" }}
                className="d-none"
                onChange={(e) => handleChangeGlimps(e)}
              />
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        {editInx === null ? (
          <div>
            <Button
              color="primary"
              onClick={(e) => addGlimps(e)}
              className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
            >
              Add
            </Button>{" "}
            &nbsp;&nbsp;
            <Button
              color="secondary"
              onClick={resetGlimps}
              className="btn-fill-lg bg-blue-dark btn-hover-yellow"
            >
              Reset
            </Button>
          </div>
        ) : (
          <Button
            color="primary"
            className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
            onClick={(e) => updateGlimps(e)}
          >
            Update
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default GlimpsModel;
