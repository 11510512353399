import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Axios from "../../utilities/axios";
import { useHistory } from "react-router";
const Studentlist = () => {
  const [rows, setRows] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [clases, setClases] = React.useState([]);
  const [selectedClass, setSelectedClass] = React.useState("");
  const [selectedCourse, setSelectedCourse] = React.useState("");
  React.useEffect(() => {
    CallApi(selectedCourse, selectedClass);
    //eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    CallApi();
    //eslint-disable-next-line
  }, [selectedCourse, selectedClass]);
  const CallApi = () => {
    const uid = localStorage.getItem("uid");
    Axios.get(
      `teacher/getstudents_under_teacher/${uid}/${
        selectedCourse !== "" ? selectedCourse : 0
      }/${selectedClass !== "" ? selectedClass : 0}`
    )
      .then((res) => {
        // console.log(res.data, "res.data");
        setRows(res.data.data);
        setCourses(res.data.courses);
        setClases(res.data.classes);
      })
      .catch((err) => {
        console.log(err);
      });
    //   navigator.clipboard.writeText(JSON.stringify(res?.data));
  };
  const columns = [
    { field: "id", headerName: "ID Number", width: 120, hide: true },
    { field: "fullname", headerName: "Name", width: 180 },
    {
      field: "course_name",
      headerName: "Course Name",
      width: 200,
    },
    {
      field: "email_id",
      headerName: "Email id",
      width: 210,
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      width: 190,
    },
    {
      field: "register_at",
      headerName: "Register Date",
      width: 190,
    },
    {
      field: "associated_from",
      headerName: "Associated from",
      width: 240,
    },
    {
      field: "address",
      headerName: "Address",
      width: 180,
    },
  ];
  const history = useHistory();
  return (
    <div>
      {rows ? (
        <div className="card height-auto">
          <div className="card-body">
            <div className="heading-layout1 row">
              <div className="item-title col-md-4">
                <h4>Student List</h4>
              </div>
              <div className="item-title col-md-4">
                <FormControl fullWidth>
                  <InputLabel id="course-duration-select-helper-label">
                    Select Course
                  </InputLabel>
                  <Select
                    labelId="course-duration-select-helper-label"
                    id="course-duration-select-helper"
                    value={selectedCourse}
                    label="Course Duration"
                    onChange={(e) => setSelectedCourse(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {courses?.map((item) => (
                      <MenuItem value={`${item?.course_id}`}>
                        {item?.course_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="item-title col-md-4">
                <FormControl fullWidth>
                  <InputLabel id="course-duration-select-helper-label">
                    Select Class
                  </InputLabel>
                  <Select
                    labelId="course-duration-select-helper-label"
                    id="course-duration-select-helper"
                    value={selectedClass}
                    label="Course Duration"
                    onChange={(e) => setSelectedClass(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {clases?.map((item) => (
                      <MenuItem value={`${item?.class_id}`}>
                        {item?.class_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div
              className="table-responsive"
              style={{ height: 650, width: "100%" }}
            >
              <DataGrid
                components={{
                  Toolbar: GridToolbar,
                }}
                rows={rows}
                columns={columns}
                pageSize={10}
                onSelectionModelChange={(newSelection) =>
                  // alert(JSON.stringify(newSelection))
                  history.push(`/Student Profile/${newSelection[0]}`)
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div id="preloader"></div>
      )}
    </div>
  );
};

export default Studentlist;
