import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Axios from "../utilities/axios";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import moment from "moment-timezone";

const Schedule = () => {
  const [rows, setRows] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [selectedCourse, setSelectedCourse] = React.useState("");
  React.useEffect(() => {
    let id = 0;
    CallApi(id);
  }, []);
  React.useEffect(() => {
    if (selectedCourse !== "") {
      CallApi(selectedCourse);
    } else {
      CallApi(0);
    }
  }, [selectedCourse]);
  const columns = [
    { field: "id", headerName: "Serial Number", width: 120, hide: true },
    { field: "data", headerName: "Date", width: 190 },
    {
      field: "day",
      headerName: "Week Day",
      width: 180,
    },
    {
      field: "course_name",
      headerName: "Course Name",
      width: 220,
    },
    {
      field: "slot_time",
      headerName: "Slot Time",
      width: 180,
    },
    {
      field: "student_count",
      headerName: "Students",
      width: 180,
      type: "number",
    },
    // {
    //   field: "duration",
    //   headerName: "Duration",
    //   width: 145,
    // },
  ];
  const CallApi = (id) => {
    const uid = localStorage.getItem("uid");
    Axios.get(`teacher/teacher_schedule/${uid}/${id}`)
      .then((res) => {
        let s = res?.data?.data;
        // console.log(res?.data?.courses, "result");
        //  let arr = res?.data?.data;
        let t = moment.tz.guess();
        console.log(t, "t");
        s.map((item, i) => {
          item.slot_time = moment(item.slot_time)
            .tz(t)
            .format("dddd hh:mm A z");
          return item;
        });
        setCourses(res?.data?.courses);
        s?.map((item, index) => (item.id = index + 1));
        //add id as index
        setRows(s && s);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      {rows ? (
        <div className="card height-auto">
          <div className="card-body">
            <div className="heading-layout1 row">
              <div className="item-title col-md-8">
                <h4>Schedule</h4>
              </div>
              <div className="item-title col-md-4">
                <FormControl fullWidth>
                  <InputLabel id="course-duration-select-helper-label">
                    Select Course
                  </InputLabel>
                  <Select
                    labelId="course-duration-select-helper-label"
                    id="course-duration-select-helper"
                    value={selectedCourse}
                    label="Course Duration"
                    onChange={(e) => setSelectedCourse(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {courses.map((item) => (
                      <MenuItem value={`${item?.course_id}`}>
                        {item?.course_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* {console.log(selectedCourse)} */}
              </div>
            </div>
            <div
              className="table-responsive"
              style={{ height: 650, width: "100%" }}
            >
              <DataGrid
                components={{
                  Toolbar: GridToolbar,
                }}
                rows={rows}
                columns={columns}
                //     RowId={(row) => row.id}
                pageSize={10}
                //     rowsPerPageOptions={[10]}
                //     onSelectionModelChange={(newSelection) =>
                //       tableRowClicked(newSelection)
                //     }
              />
            </div>
          </div>
        </div>
      ) : (
        <div id="preloader"></div>
      )}
    </div>
  );
};

export default Schedule;
