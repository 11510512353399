import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Axios from "../../utilities/axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast } from "react-toastify";
import { TimeZones } from "./../../utilities/Timezones";
import moment from "moment-timezone";

const Cateachers = (props) => {
  window.onbeforeunload = function () {
    localStorage.removeItem("selectedCourse");
  };
  const { className } = props;
  const [presentCourseId, setPresentCourseId] = React.useState("");
  const [selectedClassId, setSelectedClassId] = React.useState("");
  const [personName, setPersonName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [disableClass, setDisableClass] = React.useState(false);
  const [array, setArray] = React.useState([]);
  const [classes, setClasses] = React.useState(null);
  const [toggole, setToggole] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [associated, setAssociated] = React.useState(null);
  const [rows1, setRows1] = React.useState([]);
  const [dummy, setDummy] = React.useState(false);
  const [inviteClicked, setInviteClicked] = React.useState(false);
  const [timee, setTimee] = React.useState(new Date());
  const [timeee, setTimeee] = React.useState(null);
  const [editClassActivated, setEditClassActivated] = React.useState(false);
  const [latestRows, setLatestRows] = React.useState([]);
  const [teachercount, setTeacherCount] = React.useState({
    user_id: "",
    course_id: "",
    count: 0,
  });
  const [teachersData, setTeachersData] = React.useState([]);
  const [timerelated, setTimerelated] = React.useState("");
  const [selectedWeek, setSelectedWeek] = React.useState();
  const courseListChange = (event) => {
    if (event.target.value == "") {
      localStorage.removeItem("selectedCourse");
    } else {
      localStorage.setItem("selectedCourse", event.target.value);
    }
    setPresentCourseId(event.target.value);
  };
  const onChangeTimeZone = (event) => {
    // console.log(event.target.value,"event");
    let f = TimeZones.filter((item) => item.abbr === event.target.value);
    let r = f[0]?.utc[0];
   
    // f[0].utc.length > 0 ? f[0]?.utc[1] :
    setTimerelated(r);
    setNewClass({
      ...newClass,
      time: moment(event.target.value).format("HH:mm A"),
    });
    setNewClass({ ...newClass, time_zone: event.target.value });
  };
  // console.log(newClass, "timerelated");
  const [diable, setDiable] = React.useState(false);
  const [newClass, setNewClass] = React.useState({
    course_id: "",
    class_name: "",
    class_day: "",
    slot_time: null,
    created_by: "",
    time_zone: "",
    time: "",
  });
  const [invite, setInvite] = React.useState({
    email_id: "",
  });
  const courseDurationChange = (event) => {
    localStorage.setItem("selectedClass", event.target.value);
    setSelectedClassId(event.target.value);
  };

  const handleChange1 = (event) => {
    setPersonName(event.target.value);
  };
  const columns = [
    { field: "id", headerName: "ID", width: 110, hide: true },
    {
      field: "course",
      headerName: "Course Name",
      width: 190,
    },
    {
      field: "name",
      headerName: "Teacher",
      width: 185,
    },
    {
      field: "student_count",
      headerName: "Student Count",
      width: 155,
    },
    {
      field: "email_id",
      headerName: "E-mail Address",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 140,
    },
    {
      field: "actions",
      headerName: "Action",
      width: 145,
      sortable: false,
      renderCell: RenderDetailsButton,
      disableClickEventBubbling: true,
    },
  ];
  const columns1 = [
    { field: "id", headerName: "ID", width: 150, hidden: true, sortable: true },
    {
      field: "class_name",
      headerName: "Class",
      width: 200,
      type: "string",
    },
    {
      field: "fullname",
      headerName: "Teacher Name",
      width: 250,
    },
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      width: 200,
      renderCell: DetailsButton,
    },
  ];
  const newclass = (word) => {
    if (presentCourseId == "") {
      toast("Please select a course First", {
        type: "error",
        theme: "dark",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
      });
      return;
    } else {
      setEditClassActivated(true);
      setNewClass({
        course_id: presentCourseId,
        class_name: "",
        class_day: "",
        slot_time: null,
        created_by: "",
        // timezone: "",
      });
      setTimeee(null);
      setToggole(!toggole);
      if (word === "NT") {
        setDisableClass(true);
        Axios.get(`teacher/get_teacher_bycourse/${presentCourseId}`)
          .then((res) => {
            let data = res?.data?.response;
            let selClass = data.filter(
              (item) => item.new_id === selectedClassId
            );
            // console.log(...selClass, "selClass");
            let Data = {
              class_id: selClass[0]?.class_id,
              class_name: selClass[0]?.class_name,
              class_day: "",
              slot_time: "",
              time: "",
              time_zone: "",
            };
            setNewClass(Data);
            // setNewClass({
            //   ...selClass[0],
            //   course_id: "",
            //   class_name: "",
            //   class_day: "",
            //   slot_time: null,
            //   created_by: "",
            //   time_zone: "",
            //   time: "",
            // });
          })
          .catch((err) => {
            console.log(err);
          });
        // Axios.get(`teacher/get_teacher_bycourse/${presentCourseId}`)
        //   .then((res) => {
        //     let data = res?.data?.response;
        //     let selClass = data.filter((item) => item.class_id === selectedClassId);
        //     setNewClass(selClass[0]);
        //   })
        // .catch()
      } else {
        setDisableClass(false);
      }
    }
  };
  const newtteacher = () => {
    setOpen(!open);
  };
  const handleCreate = () => {
    // console.log(newClass);
    if (newClass.class_name === "") {
      toast.error("Please enter class name");
    } else if (newClass.class_day === "") {
      toast.error("Please select Class Day");
    } else if (timee == null) {
      toast.error("Please select slot time");
    } else {
      let uid = localStorage.getItem("uid");
      let d = newClass;
      d.created_by = uid;
      console.log(newClass.class_day,"yyyyyyyy",timee,"yyyyyyyy",timerelated)
      d.slot_time = moment
        .tz(
         `${newClass.class_day} ${timee}`, "dddd hh:mm",
          timerelated 
        ).utc()
        .format();
       

      // console.log(
      //   moment
      //     .tz(moment(`${newClass.class_day} ${"5:30"}`, "dddd hh:mm").format(), timerelated)
      //     .utc()
      //     .format(),
      //   d.slot_time,
      //   "d.slot_time",
      //   d.time_zone
      // );
      d.course_id = presentCourseId;
      // return;
      Axios.post("courses/create_class", d)
        .then((res) => {
          let clas = res?.data;
          if (res?.data?.message === "success") {
            toast("Class Created Successfully", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimerelated("");
            setClasses(clas?.class);
            setNewClass({
              course_id: "",
              class_name: "",
              class_day: "",
              slot_time: new Date(),
              created_by: "",
              timezone: "",
              time: "",
            });
            setToggole(!toggole);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  React.useEffect(() => {
    let a = [
      {
        name: "Monday",
        value: "monday",
      },
      {
        name: "Tuesday",
        value: "tuesday",
      },
      {
        name: "Wednesday",
        value: "wednesday",
      },
      {
        name: "Thursday",
        value: "thursday",
      },
      {
        name: "Friday",
        value: "friday",
      },
      {
        name: "Saturday",
        value: "saturday",
      },
      {
        name: "Sunday",
        value: "sunday",
      },
    ];
    setSelectedWeek(a && a);
    let uid = localStorage.getItem("uid");
    Axios.get(`courses/get_all_courses_for_courseadmin/${uid}`)
      .then((res) => {
        let r = res?.data?.data[0];
        let arr = [];
        r?.map((item) =>
          arr.push({
            cname: item.course_name,
            id: item.id,
          })
        );
        setArray(arr);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      window.localStorage.removeItem("selectedCourse");
    };
  }, []);

  React.useEffect(() => {
    callData();
    //eslint-disable-next-line
  }, [presentCourseId]);

  const callData = () => {
    Axios.get(
      `teacher/get_teacher_bycourse/${
        presentCourseId === "" ? 0 : presentCourseId
      }`
    )
      .then((res) => {
        // setLoading(false);
        let r = res?.data;
        // console.log("result from API", r);
        setRows(r.data.length > 0 ? r.data : []);
        let cls = res?.data?.response;
        let teac = r?.teachers;
        setRows1(teac ? teac : []);
        // setLoading(true && true);
        setTeachersData(res?.data?.teachers);
        setClasses(cls && cls);
        let arr = r?.data.filter((item) => item.status === "associate");
        if (presentCourseId == "") {
          setAssociated([]);
        } else {
          setAssociated(arr.length > 0 ? arr : arr && arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCount = () => {
    let data = teachercount;
    data.course_id = presentCourseId;
    // alert("hello");
    Axios.post("teacher/teacher_count_change", data)
      .then((res) => {
        if (res?.data.status === 200) {
          callData();
          setTeacherCount({
            ...teachercount,
            count: "",
            user_id: "",
            course_id: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClickADD = () => {
    if (personName == "") {
      toast("Please Select Teacher to continue", {
        type: "warning",
        theme: "dark",
        // position: "top-center",
      });
      return;
    }
    let uid = localStorage.getItem("uid");
    let data = {
      course_id: presentCourseId,
      user_id: personName,
      assigned_by: uid,
      id: selectedClassId,
    };
    Axios.post("teacher/add_teacher_to_class", data)
      .then((res) => {
        if (res?.data.message === "success") {
          toast("Teacher Added Successfully", {
            position: "top-center",
            autoClose: 2000,
            theme: "colored",
            type: "success",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setPersonName("");
          setLatestRows(res?.data?.data);
          setSelectedClassId("");
          setDummy(!dummy);
        } else {
          toast(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            theme: "colored",
            type: "error",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    let aa;
    if (selectedClassId !== "") {
      aa =
        latestRows.length === 0
          ? teachersData.filter((item) => item.new_id == selectedClassId)
          : latestRows.filter((item) => item.new_id == selectedClassId);
      setRows1(aa && aa);
      setDummy(!dummy);
    } else {
      if (latestRows.length === 0) {
        // console.log("teacher count 402", teachersData);
        setRows1(teachersData);
        setDummy(!dummy);
      } else {
        // console.log("teacher count 406", latestRows);
        setRows1(latestRows.length > 0 ? latestRows : []);
        setDummy(!dummy);
      }
    }
    // let a = localStorage.getItem("dummy");
    // if (a === "true") {
    //   setRows1([]);
    //   setDummy(!dummy);
    // }
    //eslint-disable-next-line
  }, [selectedClassId]);
  const handleInvite = () => {
    setDiable(true);
    setInviteClicked(true);
    let data = {
      email_id: invite.email_id,
      role: "teacher",
    };
    Axios.post("user_details/invite", data)
      .then((res) => {
        toast("Invite sent successfully", {
          type: "success",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setInvite({ email_id: "" });
        setDiable(false);
        setInviteClicked(false);
        newtteacher();
      })
      .catch((err) => {
        setDiable(false);
        console.log(err);
      });
  };
  const handleChangeTime = (e) => {
    setNewClass({
      ...newClass,
      time: moment(e.target.value, "HH:mm").format("hh:mm A"),
    });
    setTimee(e.target.value);
    setTimeee(e.target.value);
  };
  const getTeachers = async () => {
    await Axios.get(`teacher/get_teacher_bycourse/${presentCourseId}`)
      .then((res) => {
        let data = res?.data?.data?.filter(
          (item) => item.status === "associate"
        );
        setAssociated(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditClass = () => {
    setEditClassActivated(false);
    if (selectedClassId != "") {
      setToggole(!toggole);
      Axios.get(`teacher/get_teacher_bycourse/${presentCourseId}`)
        .then((res) => {
          let data = res?.data?.response;
          let selClass = data.filter((item) => item.new_id === selectedClassId);
          // console.log("selClass", selClass);
          setNewClass(selClass[0]);
           
          let f = TimeZones.filter((item) => item.abbr === selClass[0].time_zone);
          let r = f[0]?.utc[0];
          
   
    // f[0].utc.length > 0 ? f[0]?.utc[1] :
    setTimerelated(r);
          // console.log(
          //   selClass?.[0]?.slot_time,
          //   "newClass",
          //   moment(selClass?.[0]?.slot_time, "hh:mm A").format("HH:mm")
          // );
          setTimeee(
            moment(selClass?.[0]?.slot_time, "hh:mm A").format("HH:mm")
          );
          setTimee(
            moment(selClass?.[0]?.slot_time, "hh:mm A").format("HH:mm")
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast("Please select a class", {
        type: "info",
        theme: "colored",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };
  const handleUpdate = async () => {
    setEditClassActivated(false);
    // console.log("newClass", newClass);

    let data = {
      id: selectedClassId,
      class_name: newClass?.class_name,
      class_day: newClass?.class_day,
      time: newClass?.time,
      slot_time: moment
        .tz(
          `${newClass?.class_day} ${timee}`, "dddd hh:mm",
          timerelated
        )
        .utc()
        .format(),
        
      time_zone: newClass?.time_zone,
      course_id: presentCourseId,
    };
   
    
    await Axios.post("courses/update_class", data).then((res) => {
      if (res?.data?.message === "success") {
        setClasses(res?.data?.data);
        let newArray = res?.data?.teachers;
        let newEle = newArray.filter((item) => item.new_id === selectedClassId);
        setRows1(newEle);
        setLatestRows(newArray);
        toast("Class Updated Successfully", {
          type: "success",
          theme: "colored",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setToggole(!toggole);
        setEditClassActivated(true);
        setNewClass({
          course_id: "",
          class_name: "",
          class_day: "",
          slot_time: null,
          created_by: "",
          time: "",
        });
        setTimeee(new Date());
      } else {
        toast(res.data.message, {
          type: "error",
          theme: "colored",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    });
  };
  const handleDelete = () => {
    setEditClassActivated(false);
    let data = {
      id: selectedClassId,
      class_name: newClass?.class_name,
      class_day: newClass?.class_day,
      slot_time: timee,
      course_id: presentCourseId,
    };
    Axios.post("courses/delete_class", data)
      .then((res) => {
        if (res?.data?.message === "success") {
          setClasses(res?.data?.data);
          toast("Class Deleted Successfully", {
            type: "success",
            theme: "colored",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setClasses(res?.data?.data);
          setToggole(!toggole);
          setEditClassActivated(true);
          setNewClass({
            course_id: "",
            class_name: "",
            class_day: "",
            slot_time: null,
            created_by: "",
            time: "",
          });
          setTimeee(new Date());
        } else {
          toast(res.data.message, {
            type: "error",
            theme: "colored",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleFocusClass = (e) => {
    let data = {
      id: selectedClassId == "" ? 0 : selectedClassId,
      course_id: presentCourseId,
    };
    Axios.post("teacher/get_class_teacher", data)
      .then((res) => {
        // console.log("res", res);
        let result = res?.data?.data;
        setRows1(result.length > 0 ? result : []);
        setLatestRows(result.length.length > 0 ? result : []);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const handleAddNewClass = () => {
  //   alert("hello peter!!");
  // };
  return (
    <React.Fragment>
      {rows ? (
        <div className="card height-auto">
          <div className="card-body">
            <section className="mg-b-20">
              <section>
                <div className="row mg-t-20 month-add">
                  <div className="col-md-5 col-xl-5 col-lg-5 col-12 form-group">
                    <h4>Classes & Teacher Association</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 col-xl-4 col-lg-4 col-12 form-group">
                    <FormControl fullWidth>
                      <InputLabel id="course-list-select-helper-label">
                        Select Course
                      </InputLabel>
                      <Select
                        labelId="course-list-select-helper-label"
                        id="course-list-select-helper"
                        value={presentCourseId}
                        label="Select Course"
                        onChange={(e) => courseListChange(e)}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {array &&
                          array.map((item) => (
                            <MenuItem value={item?.id} key={item.user_id}>
                              {item?.cname?.toUpperCase()}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </section>

              <section>
                <div className="row mg-t-20 month-add">
                  <div className="col-md-5 col-xl-5 col-lg-5 col-12 form-group">
                    <h4>Add Teacher to the Class</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 col-xl-4 col-lg-4 col-12 form-group">
                    <FormControl fullWidth>
                      <InputLabel id="course-duration-select-helper-label">
                        Select Class
                      </InputLabel>
                      <Select
                        labelId="course-duration-select-helper-label"
                        id="course-duration-select-helper"
                        value={selectedClassId}
                        label="Select Class"
                        onChange={(e) => courseDurationChange(e)}
                        onFocus={(e) => handleFocusClass(e)}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {classes &&
                          classes?.map((item) => (
                            <MenuItem value={item.new_id} key={item.new_id}>
                              {item?.class}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-5 col-xl-5 col-lg-5 col-12 form-group r-m add-in">
                    <FormControl fullWidth>
                      <InputLabel id="course-duration-select-helper-label">
                        Associate Teacher to Class
                      </InputLabel>
                      <Select
                        labelId="course-duration-select-helper-label"
                        id="course-duration-select-helper2"
                        value={personName}
                        label="Associate Teacher to Class"
                        onChange={(e) => handleChange1(e)}
                        onFocus={() => getTeachers()}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {associated &&
                          associated?.map((item) => (
                            <MenuItem value={item.user_id} key={item.user_id}>
                              {item?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <button
                      type="button"
                      onClick={() => handleClickADD()}
                      className="btn-fill-lg bg-blue-dark btn-hover-yellow add-in-btn"
                    >
                      Add
                    </button>
                  </div>
                  <div className="col-md-2 col-xl-2 col-lg-2 col-12 form-group r-m add-in">
                    <button
                      type="button"
                      onClick={() => handleEditClass()}
                      className="btn-fill-lg bg-blue-dark btn-hover-yellow add-in-btn"
                    >
                      Edit Class
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <button
                      type="button"
                      className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark radius-30 c-n-c"
                      onClick={() => newclass()}
                    >
                      + Create New Class
                    </button>
                  </div>
                  {selectedClassId !== "" && (
                    <div className="col-md-4">
                      <button
                        type="button"
                        className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark radius-30 c-n-c"
                        onClick={() => newclass("NT")}
                      >
                        + Create New Timing for Class
                      </button>
                    </div>
                  )}
                  {/* ravi */}
                </div>
              </section>
              <section className="mg-t-20">
                <div className="table-responsive" style={{ height: "450px" }}>
                  <DataGrid
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    rows={rows1}
                    columns={columns1}
                    pageSize={5}
                    autoPageSize={true}
                    rowsPerPageOptions={[10]}
                    // loading={loading}
                  />
                </div>
              </section>
              <section className="mg-t-20">
                <h4>Invite Teachers</h4>
                <div className="row mg-t-20">
                  <div className="col-md-12 col-xl-12 col-lg-12 col-12 form-group">
                    <div className="i-a-teacher">
                      <button
                        type="button"
                        className="btn-fill-lg bg-blue-dark btn-hover-yellow radius-30"
                        onClick={newtteacher}
                      >
                        + Invite More Teachers
                      </button>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <div className="row mg-t-20 month-add">
                  <div className="col-md-5 col-xl-5 col-lg-5 col-12 form-group">
                    <h4>Allocate Class Strength to the Teacher </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 col-xl-4 col-lg-4 col-12 form-group">
                    <FormControl fullWidth>
                      <InputLabel id="teacher-list-select-helper-label">
                        Select Teacher
                      </InputLabel>
                      <Select
                        labelId="teacher-list-select-helper-label"
                        id="teacher-list-select-helper"
                        value={teachercount.user_id}
                        label="Select Teacher"
                        onChange={(e) =>
                          setTeacherCount((prevState) => ({
                            ...prevState,
                            user_id: e.target.value,
                          }))
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {associated &&
                          associated?.map((item) => (
                            <MenuItem value={item.user_id} key={item.user_id}>
                              {item?.name?.toUpperCase() +
                                "(" +
                                item.student_count +
                                ")"}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-3 col-xl-3 col-lg-3 col-12 form-group add-in">
                    <TextField
                      id="outlined-basic"
                      label="Strength"
                      variant="outlined"
                      type="number"
                      value={teachercount?.count}
                      onChange={(event) =>
                        setTeacherCount((prevState) => ({
                          ...prevState,
                          count: event.target.value,
                        }))
                      }
                    />
                    <button
                      type="button"
                      className="btn-fill-lg bg-blue-dark btn-hover-yellow add-in-btn"
                      onClick={() => handleCount()}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </section>
            </section>
            <section>
              <h4>Teacher Details</h4>
              <div className="table-responsive" style={{ height: "650px" }}>
                <DataGrid
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                />
              </div>
            </section>
          </div>
        </div>
      ) : (
        <div id="preloader"></div>
      )}
      <Modal isOpen={open} toggle={newtteacher} className={className}>
        <ModalHeader toggle={newtteacher}>Invite New Teacher</ModalHeader>
        <ModalBody>
          <form className="new-added-form">
            <div className="row"></div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-12 form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="Email"
                  name="course_name"
                  value={invite.email_id}
                  onChange={(e) => {
                    setInvite({
                      email_id: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={diable}
            onClick={() => {
              handleInvite();
            }}
            className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
          >
            {inviteClicked ? "Please Wait..." : "Invite"}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={newtteacher}
            className="btn-fill-lg bg-blue-dark btn-hover-yellow"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={toggole} toggle={newclass} className={className}>
        <ModalHeader toggle={newclass}>
          {editClassActivated ? "Add New" : "Edit"} Class
        </ModalHeader>
        <ModalBody>
          <form className="new-added-form">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-12 form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="Class Name"
                  name="Class_name"
                  disabled={disableClass}
                  value={newClass.class_name}
                  onChange={(e) =>
                    setNewClass({ ...newClass, class_name: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-12 form-group mb-4">
                <FormControl fullWidth>
                  <InputLabel id="course-duration-select-helper-label">
                    Class Day
                  </InputLabel>
                  <Select
                    labelId="course-duration-select-helper-label"
                    id="course-duration-select-helper"
                    label="Week day"
                    name="class_dat"
                    value={newClass.class_day}
                    onChange={(e) =>
                      setNewClass({ ...newClass, class_day: e.target.value })
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {selectedWeek?.map((item) => (
                      <MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="mt-4">
                  <FormControl fullWidth>
                    <InputLabel id="course-list-select-helper-labell">
                      Select Timezone
                    </InputLabel>
                    <Select
                      labelId="course-list-select-helper-labell"
                      id="course-list-select-helperr"
                      value={`${newClass.time_zone}`}
                      label="Select Timezone"
                      onChange={(e) => onChangeTimeZone(e)}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {TimeZones.map((item, index) => (
                        <MenuItem value={item.abbr} key={index}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="mt-4">
                  <TextField
                    id="time"
                    label="Slot Time"
                    type="time"
                    value={timeee}
                    onChange={(e) => handleChangeTime(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          {editClassActivated ? null : (
            <Button
              color="primary"
              onClick={() => handleDelete()}
              className="btn btn-danger"
            >
              Delete Class
            </Button>
          )}
          {/* <Button
            color="primary"
            onClick={() => handleAddNewClass()}
            className="btn-fill-lg bg-blue-dark btn-hover-yellow"
          >
            Add new Class
          </Button> */}
          <Button
            color="primary"
            onClick={() => {
              editClassActivated ? handleCreate() : handleUpdate();
            }}
            className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
          >
            {editClassActivated ? "Create" : "Update"}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={newclass}
            className="btn-fill-lg bg-blue-dark btn-hover-yellow"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Cateachers;

const DetailsButton = (params) => {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [associated, setAssociated] = React.useState(null);
  const [personName, setPersonName] = React.useState("");
  const getTeachers = async () => {
    // if()
    let uid = localStorage.getItem("selectedCourse");
    if (uid === null) {
      toast("Please Select parcticular course", {
        type: "info",
        theme: "dark",
      });
    }
    await Axios.get(`teacher/get_teacher_bycourse/${uid}`)
      .then((res) => {
        let data = res?.data?.data?.filter(
          (item) => item.status === "associate"
        );
        setAssociated(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChange1 = (event) => {
    setPersonName(event.target.value);
  };
  const handleReplace = () => {
    if (params.row.user_id == personName) {
      toast("Please Select Other Teacher", {
        type: "error",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let cid = localStorage.getItem("selectedCourse");
      // console.log(params.row, "$$$$$$$$$$$$$$$$$$$$$$");
      let data = {
        user_id: personName == "" ? 0 : personName,
        class_id: params.row.class_id,
        course_id: cid,
        assigned_id: params.row.assigned_id,
      };
      // data.user_id = personName;
      // return;
      Axios.post("teacher/replace_teacher_to_class", data)
        .then((res) => {
          if (res?.data?.message === "success") {
            toast("Replace Successfully", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            handleClose();
            if (res?.data?.data.length === 0) {
              params.api.setRows([]);
            } else {
              params.api.setRows(res?.data?.data);
              // alert("HEllo peter");
            }
          } else {
            toast(res.data.message, {
              type: "error",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <strong>
      <button className="btn btn-primary" onClick={handleShow}>
        Replace Teacher
      </button>
      <Modal isOpen={show} toggle={handleClose}>
        <ModalHeader toggle={handleClose}>
          Replace New Teacher for {params.row.class_name}
        </ModalHeader>
        <ModalBody>
          <form className="new-added-form">
            <div className="row"></div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-12 form-group mb-4">
                <FormControl fullWidth>
                  <InputLabel id="course-duration-select-helper-label">
                    Select Other Teacher to Class
                  </InputLabel>
                  <Select
                    labelId="course-duration-select-helper-label"
                    id="course-duration-select-helper2"
                    value={personName}
                    label="Associate Teacher to Class"
                    onChange={(e) => handleChange1(e)}
                    onFocus={() => getTeachers()}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {associated &&
                      associated?.map((item) => (
                        <MenuItem value={item.user_id} key={item.user_id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              handleReplace();
            }}
            className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
          >
            Replace
          </Button>{" "}
          <Button
            color="secondary"
            onClick={handleClose}
            className="btn-fill-lg bg-blue-dark btn-hover-yellow"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </strong>
  );
};

const RenderDetailsButton = (params) => {
  const onEdit = (paras) => {
    const uid = localStorage.getItem("uid");
    const course = localStorage.getItem("selectedCourse");
    // alert(course);
    if (course == "" || course == null) {
      toast("Please Select Course to continue", {
        type: "error",
        theme: "dark",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    } else {
      let data = {
        assigned_by: uid,
        course_id: paras.row.course_id,
        user_id: paras.row.user_id,
      };
      Axios.post("course_assign/course_assign", data)
        .then((res) => {
          if (res?.data?.message === "success") {
            toast(res?.data?.message, {
              type: "success",
              theme: "colored",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            params.api.setRows(res?.data?.data);
          } else {
            toast(res?.data?.message, {
              type: "warning",
              theme: "colored",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            return;
          }
        })
        .catch((err) => {
          toast("Internal Server Error", {
            type: "error",
            theme: "colored",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        });
    }
  };
  return (
    <strong>
      <button
        className={
          params?.row?.status === "associate"
            ? "btn btn-danger"
            : "btn btn-success"
        }
        onClick={() => {
          onEdit(params);
        }}
      >
        {params?.row?.status === "associate" ? "Unassociate" : "Associate"}
      </button>
    </strong>
  );
};
