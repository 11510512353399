import React, { createContext, useReducer } from "react";
import { AUTH_TYPES } from "../utilities/authTypes";
import { admin, courseadmin, teacher } from "../utilities/Routs";

let routes = {
  admin,
  courseadmin,
  teacher,
};

export const authContext = createContext();
const initialState = {
  userDetails: "",
  role: "",
  currentUser: null,
  isAuthenticated: false,
  sideBarRoutes: [],
  selectedStudents: [],
  selectedCourse: 0,
  selectedClass: 0,
  userId: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case AUTH_TYPES.LOGIN:
      return {
        ...state,
        userDetails: action.payload.userId,
        isAuthenticated: true,
        role: action.payload.role,
        sideBarRoutes: routes[action.payload.role],
      };
    case AUTH_TYPES.LOGOUT:
      return {
        ...state,
        userDetails: "",
        isAuthenticated: false,
        role: "",
        currentUser: null,
      };
    case AUTH_TYPES.SELECTED_STUDENTS:
      return {
        ...state,
        selectedStudents: action.payload,
      };
    case AUTH_TYPES.LOGIN_CHECK:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case AUTH_TYPES.SET_ROLE:
      return {
        ...state,
        sideBarRoutes: action.payload,
      };
    case AUTH_TYPES.SET_USER:
      // console.log("from conttext", action.payload);
      // let uid = localStorage.getItem("uid");
      return {
        ...state,
        currentUser: action.payload,
        // role: action.payload.role,
        // userId: uid,
      };
    case AUTH_TYPES.SELECTED_COURSE:
      return {
        ...state,
        selectedCourse: action.payload,
      };
    case AUTH_TYPES.SELECTED_CLASS:
      return {
        ...state,
        selectedClass: action.payload,
      };
    default:
      return state;
  }
};

const AuthContext = ({ children }) => {
  const [authState, dispatch] = useReducer(reducer, initialState);

  return (
    <authContext.Provider value={{ authState, dispatch }}>
      {children}
    </authContext.Provider>
  );
};

export default AuthContext;
