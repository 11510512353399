import "./App.css";
import React from "react";
import { ToastContainer } from "react-toastify";
import Routes from "./navigation/Routes";
import AuthContext from "./context/AuthContext";
import Navbar from "./components/commonComponents/Navbar";
import Sidebar from "./components/commonComponents/Sidebar";

function App() {
  const [isChanged, setIsChanged] = React.useState(false);
  return (
    <>
      <AuthContext>
        <ToastContainer />
        <div id="preloader"></div>
        <div id="wrapper" className="wrapper bg-ash">
          <Navbar isChanged={isChanged} />
          <div className="dashboard-page-one">
            <Sidebar />
            <div className="dashboard-content-one">
              <Routes isChanged={isChanged} setIsChanged={setIsChanged} />
            </div>
          </div>
        </div>
      </AuthContext>
    </>
  );
}
export default App;
