import { DatePicker } from '@mui/lab';
import { Box, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material'
import React from 'react'
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import RichTextEditor from 'react-rte';
import AddSpeakers from './AddSpeakers';
import image from '../../assests/img/books.JPG'
import CloseIcon from '@mui/icons-material/Close';
import Axios from "../../utilities/axios";
import { toast } from 'react-toastify';
import { uploadFile } from "react-s3";
import { formatDate } from "../../utilities/convert";
import moment from 'moment';


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,

    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
};
const config = {
    bucketName: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_REGION,
    dirName: "folder",
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

const AddEventModel = (
    {
        setModelOpen,
        modelOpen,
        singleEventData,
        type,
        setRows

    }
) => {
    //console.log(singleEventData && singleEventData[1], type, "dataaaaaa")
    const [value, setValues] = React.useState(RichTextEditor.createEmptyValue());
    const [eventDetails, setEventDetails] = React.useState({
        description: "",
        event_name: "",
        course_id: "",
        meeting_link: "",
        end_time: "",
        start_time: "",
        event_date: null
    })
    const [speakersDetails, setSpeakersDetails] = React.useState([])
    const [courseList, setCourseList] = React.useState([])
    const [deletedSpeakers, setDeletedSpeakers] = React.useState([]);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [strt_time, setStrtTime] = React.useState(null);
    const [end_timee, setEndTimee] = React.useState(null);

    React.useEffect(() => {
        callapi();
        if (type === "edit" || type === "view") {
            const data = singleEventData;
            setEventDetails(data?.[0]?.[0]);
            setStrtTime(moment(data?.[0]?.[0].start_time).format("HH:mm"))
            
            setEndTimee(moment(data?.[0]?.[0].end_time).format("HH:mm"))
            setSpeakersDetails(data[1])
            setValues(
                RichTextEditor.createValueFromString(
                    data?.[0]?.[0].description,
                    "html"
                )
            );
            // delete formErrors.dob;
        }
        //alert("model")
    }, [])
    const handleClose = () => {
        setModelOpen(!modelOpen);
        setEventDetails({
            description: "",
            event_name: "",
            course_id: "",
            meeting_link: "",
            end_time: "",
            start_time: "",
            event_date: null
        })
        setStrtTime(null)
        setEndTimee(null)
        setValues(RichTextEditor.createEmptyValue())
        setSpeakersDetails([])
    }
    const handleChange = (e) => {
        setValues(e);
        setEventDetails({ ...eventDetails, description: e.toString("html") });

    };
    const uploadImage = async (e) => {
        setSelectedFile(e.target.files[0]);
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setEventDetails({ ...eventDetails, image_url: reader.result });
            }
        };
        reader.readAsDataURL(e.target.files[0]);
    };
    const handleUpload = async (file) => {
        await uploadFile(file, config)
            .then((data) => {
                if (data?.result?.ok) {
                    console.log(data?.location, "data?.location")
                    handleSubmit(data?.location);
                } else {
                    alert("error");
                }
            })
            .catch((err) => handleSubmit("NoImage"));
    };
    const handleSubmit = (image) => {

        let det = {
            ...eventDetails,
            event_date: formatDate(eventDetails.event_date),
        };
        if(type==='edit'){
           console.log(det.end_time.length,"sdhsgdhsgdhs")
            det.start_time=det.start_time.length>8 ? moment(det.start_time).format('hh:mm A'):det.start_time
            det.end_time=det.end_time.length>8 ? moment(det.end_time).format('hh:mm A'):det.end_time
        }
            console.log(det.start_time,"sdhsgdhsgdhs",det.end_time)
           console.log(moment(det.start_time, 'hh:mm A').isBefore(moment(det.end_time, 'hh:mm A')),"sdhsgdhsgdhs")
             console.log(moment(det.start_time).format('hh:mm A'),"sdhsgdhsgdhs",moment(det.end_time).format('hh:mm A'))
        if (det.event_name === "" || !det.event_name) {
            toast("Event name required", {
                type: "error",
                position: "top-center",

                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            //   setClicked(false);
        }
        else if (det.course_id === "" || !det.course_id) {
            toast("course name required", {
                type: "error",
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            //   setClicked(false);
        }
        else if (det.meeting_link === "" || !det.meeting_link) {
            toast("Meeting link is required", {
                type: "error",
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });

            // setClicked(false);
        }
        else if (det.event_date === "" || !det.event_date || det.event_date < moment(new Date()).format('YYYY-MM-DD')) {
            toast("event date is required", {
                type: "error",
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
        
        else if (det.start_time === "" || !det.start_time) {
            toast("Start time is required", {
                type: "error",
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
        else if (det.end_time === "" || !det.end_time) {
            toast("end time is required", {
                type: "error",
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }

        else if(!moment(det.start_time, 'hh:mm A').isBefore(moment(det.end_time, 'hh:mm A'))){
            toast("end time should be different", {
                type: "error",
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
        // else if(!moment(det.start_time, 'hh:mm A').isBefore(moment(det.end_time, 'hh:mm A'))&&type==='edit'){
        //     toast("end time should be different", {
        //         type: "error",
        //         position: "top-center",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //     });
        // }
        else {
            // console.log(moment(det.start_time,'hh mm a').format('hh mm a')<=moment(det.end_time,'hh mm a').format('hh mm a'),"time validations")
        //    console.log( moment(det.start_time, 'h:mma').isBefore(moment(det.end_time, 'h:mma')),"time compare")
           
            let uid = localStorage.getItem("uid");
            det.created_by = uid;
            det.course_id = `${det?.course_id}`;
            if (type === 'edit') {
                det.image_url = image === 'NoImage' ? eventDetails.image_url : image;
            } else {
                det.image_url = image === 'NoImage' ? "https://rushi2011.s3.amazonaws.com/folder/1637670356789_demo_image.png" : image;
            }
            // console.log({...det,speakers:speakersDetails},"dataaaa")
            const slot_time = moment
                .tz(
                    `${det.event_date} ${det.start_time}`, "YYYY-MM-DD hh:mm A", "Asia/Kolkata"

                ).utc()
                .format();
            const slot_time1 = moment
                .tz(
                    `${det.event_date} ${det.end_time}`, "YYYY-MM-DD hh:mm A", "Asia/Kolkata"

                ).utc()
                .format();
            console.log(slot_time, "slot_time")
            det.event_date = slot_time;
            det.start_time = slot_time;
            det.end_time = slot_time1;
            if (type !== "edit") {


                Axios.post("/events/add_event", { ...det, speakers: speakersDetails })
                    .then((res) => {
                        console.log(res?.data?.result, "resssssssssssss")
                        if (res?.data?.message == "success") {
                            toast("successfully Added event", {
                                type: "success",
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            setModelOpen(false)
                            setEventDetails({
                                description: "",
                                event_name: "",
                                course_id: "",
                                meeting_link: "",
                                end_time: "",
                                start_time: "",
                                event_date: null
                            })
                            setStrtTime(null)
                            setEndTimee(null)
                            setValues(RichTextEditor.createEmptyValue())
                            setSpeakersDetails([])
                            setRows(res?.data?.result)


                        }
                        else {
                            toast(res?.data?.message, {
                                type: "error",
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });



                        }
                    })
            } else {


                Axios.post("/events/edit_event", { ...det, speakers: [...speakersDetails, ...deletedSpeakers] })
                    .then((res) => {

                        if (res?.data?.status == 200) {
                            toast(res?.data?.message, {
                                type: "success",
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            setModelOpen(false)
                            setEventDetails({})
                            setSpeakersDetails([])
                            setRows(res?.data?.result)


                        }
                        else if (res?.data?.status == 400) {
                            toast(res?.data?.message, {
                                type: "error",
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            // setRows(res?.result)
                            // setModelOpen(false)
                            // setEventDetails({})
                            // setSpeakersDetails([])


                        } else {
                            toast("Something went wrong", {
                                type: "error",
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    })
            }
        }
    };
    const handleChangeStartTime = (e) => {
        // slot_time = moment
        // .tz(
        //  `${newClass.class_day} ${timee}`, "dddd hh:mm",
        //   timerelated 
        // ).utc()
        // .format();

        setEventDetails({
            ...eventDetails,
            start_time: moment(e.target.value, "HH:mm").format("hh:mm A"),
            edit_flag: type === 'edit' ? true : false

        });
        setStrtTime(e.target.value);
        if (type === 'edit') {
            setEventDetails({
                ...eventDetails,
                edit_flag: true,
            });
        }

    };
    const handleChangeEndTime = (e) => {

        setEventDetails({
            ...eventDetails,
            end_time: moment(e.target.value, "HH:mm").format("hh:mm A"),
            edit_flag: type === 'edit' ? true : false

        });
        setEndTimee(e.target.value);


    };




    const callapi = () => {
        Axios.get('/courses/get_all_courses')
            .then((res) => {
                let r = res?.data?.data[0]
                console.log(r, "rrrrrrrrrrr")
                setCourseList(r)
            })
            .catch((err) => {
                toast("Something went Wrong", {
                    type: "error",
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            })
    }

    return (
        <div> <Modal
            open={modelOpen}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="hng"
        >
            <Box sx={style}>
                <div className="row gutters-8">

                    <h4>
                        {type === 'view' ? "Event Details" : type === 'edit' ? "Edit Event" : "Add Event"}
                    </h4>

                    <span style={{
                        position: "absolute",
                        right: "11px",
                        top: "18px",
                        cursor: "pointer"
                    }}>   <CloseIcon onClick={handleClose} /></span>

                </div>


                <div>
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-6 col-xl-6 col-lg-6 col-12 form-group">
                                    <TextField
                                        id="outlined-basic"
                                        label="Event Name *"
                                        variant="outlined"
                                        value={eventDetails.event_name}
                                        disabled={type === 'view' ? true : false}
                                        onChange={(e) =>
                                            setEventDetails({ ...eventDetails, event_name: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="col-md-6 col-xl-6 col-lg-6 col-12 form-group">
                                    <FormControl fullWidth>
                                        <InputLabel id="course-duration-select-helper-label">
                                            Course Name *
                                        </InputLabel>
                                        <Select
                                            labelId="course-duration-select-helper-label"
                                            id="course-duration-select-helper"
                                            value={eventDetails?.course_id}
                                            disabled={type === 'view' ? true : false}
                                            label="Course Duration"
                                            // disabled={status}
                                            onChange={(e) =>
                                                setEventDetails({ ...eventDetails, course_id: e.target.value })
                                            }
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {courseList &&
                                                courseList.map((item) => (
                                                    <MenuItem value={item.id}>
                                                        {item?.course_name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-xl-6 col-lg-6 col-12 form-group">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Start Date of the Event"

                                            value={eventDetails.event_date}

                                            mask="dd-MM-yyyy"
                                            inputFormat="dd-MMM-yyyy"
                                            disabled={type === 'view' ? true : false}
                                            disablePast={true}
                                            onChange={(newValue) => {
                                                // handleOnChangeDate()
                                                setEventDetails({
                                                    ...eventDetails,
                                                    event_date: newValue,
                                                    edit_flag: type === 'edit' ? true : false
                                                });

                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="col-md-6 col-xl-6 col-lg-6 col-12  form-group add-in ev-field">
                                    <TextField

                                        id="time"
                                        label="Start Time"
                                        type="time"
                                        //defaultValue="07:30"
                                        disabled={type === 'view' ? true : false}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // inputProps={{
                                        //     step: 300, // 5 min
                                        // }}
                                        sx={{ width: 150 }}
                                        onChange={(e) => {
                                            handleChangeStartTime(e)
                                            // setEventDetails({
                                            //     ...eventDetails,
                                            //     start_time: moment(e.target.value, "HH:mm").format("hh:mm A"),

                                            //   });



                                        }}
                                        value={strt_time}
                                    />

                                    <TextField
                                        id="time"
                                        label="End Time"
                                        type="time"
                                        //defaultValue="07:30"
                                        disabled={type === 'view' ? true : false}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        sx={{ width: 150 }}
                                        value={end_timee}
                                        onChange={(e) => {
                                            handleChangeEndTime(e)
                                        }}
                                    />
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12 col-xl-12 col-lg-12 col-12 form-group">
                                    <TextField
                                        id="outlined-basic"
                                        label="Event Online link *"
                                        variant="outlined"
                                        value={eventDetails.meeting_link}
                                        disabled={type === 'view' ? true : false}
                                        onChange={(e) =>
                                            setEventDetails({ ...eventDetails, meeting_link: e.target.value })
                                        }
                                    />
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12 col-xl-12 col-lg-12 col-12">
                                    <div className="form-floating">
                                        <RichTextEditor
                                            placeholder="Write Something About Yourself"
                                            value={value}
                                            label="Description *"
                                            disabled={type === 'view' ? true : false}
                                            onChange={(e) =>
                                                handleChange(e)

                                            }
                                        />

                                    </div>
                                </div>
                            </div>





                        </div>
                        <div className="col-md-3">
                            <div className="up-img-sec">
                                {console.log(eventDetails, image, "imagaggagagagag")}
                                <img
                                    src={"image_url" in eventDetails ? eventDetails.image_url : image}
                                    alt="mage"
                                    className="image-fluid pointer"
                                    onClick={() => document.getElementById("upload").click()}
                                />
                                {type !== 'view' && <i
                                    className="fas fa-cloud-upload-alt pointer"
                                    onClick={() => document.getElementById("upload").click()}
                                ></i>}
                                <center>
                                    <input
                                        id="upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => uploadImage(e)}
                                        className="donotshow"
                                    />
                                </center>
                            </div>
                        </div>

                    </div>
                    <div className="row border-1" >
                        <div className="col-md-12 col-xl-12 col-lg-12 col-12">
                            <div className="form-floating">
                                <AddSpeakers speakersDetails={speakersDetails}
                                    type={type}
                                    setSpeakersDetails={setSpeakersDetails}
                                    setDeletedSpeakers={setDeletedSpeakers}
                                    deletedSpeakers={deletedSpeakers}
                                />

                            </div>
                        </div>
                    </div>
                    {type !== 'view' && <div className="row">
                        <div className="col-12 form-group mg-t-8">
                            <button
                                type="button"
                                onClick={() => {
                                    //   setClicked(true);
                                    handleUpload(selectedFile);
                                    console.log("speakersDetails", speakersDetails, eventDetails)
                                }}
                                className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                            >
                                {type === 'edit' ? "Update Event" : "Save"}
                            </button>

                        </div></div>
                    }
                </div>
            </Box>
        </Modal>
        </div>
    )
}

export default AddEventModel