import React from "react";
import Mycard from "./commonComponents/Mycard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Axios from "../utilities/axios";
import { useParams } from "react-router";
import parse from "html-react-parser";
import { DataGrid } from "@mui/x-data-grid";
const Student = () => {
  const [result, setResult] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  let { id } = useParams();
  React.useEffect(() => {
    Axios.get(`/student/get_single_student/${id}`)
      .then((res) => {
        let r = res && res.data && res.data.data;
        setRows(r[2]);
        setResult(r);
      })
      .catch((err) => {
        console.log(err);
      });
    //eslint-disable-next-line
  }, []);
  const student = [
    {
      iClass: "flaticon-checklist text-red",
      name: "Fee Details",
      number: `${(result && result?.[1]?.[0]?.fee_details) || 0}`,
    },
    {
      iClass: "flaticon-open-book text-magenta",
      name: "Course(s)",
      number: `${result && result[1].length}`,
    },
    {
      iClass: "flaticon-books text-mauvelous",
      name: "Association Duration",
      number: `${result && result[0][0].associated_from}`,
    },
    {
      iClass: "flaticon-script text-blue",
      name: "Test Attended",
      number: `${result && result[1].length}`,
    },
  ];
  const columns = [
    {
      field: id,
      headerName: "ID",
      width: "200",
      hide: true,
    },
    {
      field: "course_name",
      headerName: "Course Name",
      width: "200",
    },

    {
      field: "class_name",
      headerName: "Class Name",
      width: "200",
    },
    {
      field: "attendence_date",
      headerName: "Attendance Date",
      width: "200",
    },
    {
      field: "attendence",
      headerName: "Attendance",
      width: "200",
    },
  ];
  return (
    <React.Fragment>
      {result ? (
        <div className="card height-auto">
          <div className="card-body">
            <h4 className="main-h">
              {result?.[0]?.[0]?.fullname.toUpperCase()}
            </h4>
            <section className="mg-b-20">
              <div className="row">
                <div className="col-md-8 w-topics w-t-view">
                  <Carousel responsive={responsive} infiniteLoop={true}>
                    {student.map((item, index) => (
                      <div key={index} className="carou-slider">
                        <Mycard
                          iClass={item.iClass}
                          name={item.name}
                          number={item.number}
                          key={index}
                        />
                      </div>
                    ))}
                  </Carousel>
                  <div className="card-body table-responsive">
                    <div style={{ height: 400, width: "100%" }}>
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        data={result[1]}
                        pageSize={10}
                        pageSizes={[10, 20, 30, 40, 50]}
                        totalCount={result[1].length}
                        onRowClick={(e, rowData) => {
                          console.log(rowData);
                        }}
                        // components={{}}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 w-topics w-t-view">
                  <div className="c-profile">
                    <img alt="not loaded" src={result?.[0]?.[0]?.image}></img>
                    <div className="c-content">
                      <div className="c-values">
                        {result?.[0]?.[0] &&
                          parse(result?.[0]?.[0]?.description)}
                      </div>
                    </div>
                    <div className="c-content">
                      <div>Gender</div>
                      <div className="c-values">{result?.[0]?.[0]?.gender}</div>
                    </div>
                    <div className="c-content">
                      <div>Date Of Birth</div>
                      <div className="c-values">{result?.[0]?.[0]?.dob}</div>
                    </div>
                    <div className="c-content">
                      <div>E-Mail</div>
                      <div className="c-values">
                        {result?.[0]?.[0]?.email_id}
                      </div>
                    </div>
                    <div className="c-content">
                      <div>Phone</div>
                      <div className="c-values">
                        {result?.[0]?.[0]?.phone_number}
                      </div>
                    </div>
                    <div className="c-content">
                      <div>Address</div>
                      <div className="c-values">
                        {result?.[0]?.[0]?.address}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <div id="preloader"></div>
      )}
    </React.Fragment>
  );
};
export default Student;
