import React, { useContext } from "react";
import logo from "../../src/assests/img/rushividyahyasam-small-logo.png";
import { Validator } from "../utilities/validation";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import Axios from "../utilities/axios";
import { authContext } from "../context/AuthContext";
import { AUTH_TYPES } from "./../utilities/authTypes";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
// import { useLa } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const Loginscreen = ({ setIsChanged, isChanged }) => {
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  // let location = useLocation();
  // console.log("location", location);
  // const handleChange = (prop) => (event) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const { authState, dispatch } = useContext(authContext);

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [flag, setFlag] = React.useState("");
  const [, forceUpdate] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  // const [asTeacher, setAsTeacher] = React.useState(false);
  let validator = Validator();
  let history = useHistory();
  React.useEffect(() => {
    const data = document.cookie.split("=");
    // console.log(document.cookie, "document.cookie");
    if (data[2]) {
      setChecked(true);
      setUsername(data[0]);
      setPassword(data[1]);
    }
    const user = authState.isAuthenticated;
    if (user && user !== "undefined") {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      callApi();
    } else {
      forceUpdate(true);
      validator.current.showMessages();
    }
  };
  const callApi = () => {
    const new_pass = CryptoJS.AES.encrypt(password, "rushi").toString();
    let data = {
      email_id: `${username}`,
      password: `${new_pass}`,
      flag,
    };
    Axios.post("/user_details/login", data)
      .then((res) => {
        if (res.data.status === 200) {
          if (res?.data?.message === "failed") {
            toast("No Access for you", {
              type: "error",
              theme: "colored",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            localStorage.setItem(
              "uid",
              JSON.stringify(res && res.data.user_id)
            );
            localStorage.setItem("role", res?.data?.role);
            localStorage.setItem("mainrole", res?.data?.mainrole);
            if (checked) {
              const d = "Sun, 20 Aug 2000 12:00:00 UTC";
              // d.setTime((d.getTime() - 100) * 24 * 60 * 60 * 1000);
              let expires = "expires=" + d;
              document.cookie =
                username +
                "=" +
                password +
                "=" +
                checked +
                ";" +
                expires +
                ";path=/";

              const dd = new Date();
              dd.setTime(dd.getTime() + 5 * 24 * 60 * 60 * 1000);
              let expiress = "expires=" + dd.toUTCString();
              document.cookie =
                username +
                "=" +
                password +
                "=" +
                checked +
                ";" +
                expiress +
                ";path=/";
            } else {
              //clear cookie
              const d = "Sun, 20 Aug 2000 12:00:00 UTC";
              // d.setTime((d.getTime() - 100) * 24 * 60 * 60 * 1000);
              let expires = "expires=" + d;
              document.cookie =
                username +
                "=" +
                password +
                "=" +
                checked +
                ";" +
                expires +
                ";path=/";
            }
            dispatch({
              type: AUTH_TYPES.LOGIN,
              payload: { userId: res.data.user_id, role: res.data.role },
            });
            localStorage.setItem("token", res && res.data.token);
            // toast(`Successfully logged-in as ${res && res.data.role}`, {
            //   position: "top-center",
            //   autoClose: 2000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   theme: "dark",
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
            if (res?.data?.role === "admin") {
              setIsChanged(!isChanged);
              history.push("/Account Admin Dashboard");
            } else if (res?.data?.role === "courseadmin") {
              setIsChanged(!isChanged);
              history.push("/Course Admin Dashboard");
            } else if (res?.data?.role === "teacher") {
              setIsChanged(!isChanged);
              history.push("/Teacher Dashboard");
            } else {
              toast("No Access for you", {
                type: "error",
                theme: "colored",
                position: "top-center",
              });
            }
          }
        }
      })
      .catch((err) => {
        // console.log(err);
        toast("Invalid credentials", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: "dark",
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleCheckbox = () => {
    setChecked(!checked);
  };
  // const handleCheckboxx = () => {
  //   setAsTeacher(!asTeacher);
  // };
  return (
    <>
      <div>
        {/* Preloader Start Here */}
        {/* <div id="preloader" /> */}
        {/* Preloader End Here */}
        {/* Login Page Start Here */}
        <div className="signup-page-wrap">
          <div className="login-page-content">
            <div className="login-box sign-sec">
              <div className="item-logo auth-logo">
                <img src={logo} alt="logo" />
              </div>
              <form className="login-form">
                <div className="row">
                  <div className="col-md-12 mg-b-20">
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  {/* <center> */}
                  {validator.current.message(
                    "Email",
                    username,
                    "required|email"
                  )}
                  {/* </center> */}
                  <div className="col-md-12 mg-b-20">
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <OutlinedInput
                        id="password"
                        type={values.showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>
                  </div>{" "}
                  {validator.current.message("password", password, "required")}
                </div>
                <div className="row">
                  <div className="col-md-12 mg-b-20">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Role
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={flag}
                        // disabled={diasble}
                        label="Select Role"
                        onChange={(e) => {
                          setFlag(e.target.value);
                          // console.log(e.target.value);
                          localStorage.setItem("exrole", e.target.value);
                          // setErrorRole(false);
                          // setObj({ ...obj, role: e.target.value });
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"courseadmin"}>Course Admin</MenuItem>
                        {/* <MenuItem value={"coordinator"}>Co-ordinators</MenuItem> */}
                        <MenuItem value={"teacher"}>Teacher</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                {/* <div className="form-group">
                  <label>Username :</label>
                  <input
                    type="email"
                    placeholder="Enter usrename"
                    className="form-control"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <i className="far fa-envelope" />
                </div>
                

                <div className="form-group">
                  <label>Password :</label>
                  <input
                    type="password"
                    placeholder="Enter password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <i className="fas fa-lock" />
                </div>
                {validator.current.message("password", password, "required")} */}
                <div className="form-group f-p-ali">
                  <p
                    className="forgot-btn pointer"
                    onClick={() => {
                      history.push("/forgotpassword");
                    }}
                  >
                    Forgot Password?
                  </p>
                </div>
                <div className="form-group d-flex align-items-center">
                  <div className="form-check pick-op">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="remember-me"
                      checked={checked}
                      onChange={() => handleCheckbox()}
                    />
                    <label htmlFor="remember-me" className="form-check-label">
                      Remember Me
                    </label>
                  </div>
                  {/* <div className="form-check pick-op">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="remember-me"
                      checked={asTeacher}
                      onChange={handleCheckboxx}
                    />
                    <label htmlFor="remember-me" className="form-check-label">
                      Login As Teacher
                    </label>
                  </div> */}
                </div>
                <div className="form-group">
                  <button
                    onClick={(e) => handleSubmit(e)}
                    type="submit"
                    className="login-btn"
                  >
                    Sign in
                  </button>
                </div>
                <div className="sign-up pre-link">
                  Don't have an account ? <a href="/signup">Signup now!</a>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Login Page End Here */}
      </div>
    </>
  );
};

export default Loginscreen;
